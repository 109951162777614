interface SearchIconProps {
  width?: number;
  height?: number;
  color?: string;
  alt?: string;
}

const SearchIcon: React.FC<SearchIconProps> = ({
  width = 18,
  height = 18,
  color = "#191B38",
  alt = "search-icon"
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-label={alt}
  >
    <path
      d="M19 19L15.5001 15.5M18 9.5C18 14.1944 14.1944 18 9.5 18C4.80558 18 1 14.1944 1 9.5C1 4.80558 4.80558 1 9.5 1C14.1944 1 18 4.80558 18 9.5Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SearchIcon;
