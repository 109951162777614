import { Link } from "react-router-dom";
import InfoPage from "../InfoPage";
import SectionTitle from "../../../components/SectionTitle/SectionTitle";
import pageInfo from "../pageInfo.json";

import styles from "./styles.module.scss";
import { getCanonicalUrlForInfoPage } from "utils/helpers/getCanonicalUrl";
import { formatPrice } from "utils/helpers/formatedPrice";


const AboutUs: React.FC = () => {

  const aboutUsData = pageInfo.about;
  const canonicalUrl = getCanonicalUrlForInfoPage(window.location.href);

  return (
    <InfoPage metaData={aboutUsData} canonicalUrl={canonicalUrl}>
      <h1 className={styles.seo}>Инфо страница О нас</h1>
      <SectionTitle title="О нас" containerStyles={styles["custom-title"]}/>
      <div className={styles.root}>
        <p className={styles.text}>
        Парфюмерия и косметика являются неотъемлемой частью жизни большинства людей.
        Хороший парфюм, равно как и качественный макияж, способен многое рассказать о Вас,
        о Вашем настроении, возможно, о Ваших желаниях...Оба элемента в совокупности отображают
        Ваш имидж и стиль жизни, способны изменить жизнь в один миг, оставив неизгладимое
        впечатление в сердце любимого человека!
        </p>
        <h3 className={styles.title}>
        Наш Ассортимент
        </h3>
        <p className={styles.text}>
        Интернет-магазин парфюмерии и косметики
          <Link
            data-cke-saved-href="https://parfumart.ru/"
            to="/"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.item_link}
          > Parfumart.ru
          </Link> предлагает широкий ассортимент
        оригинальной продукции от производителей с мировым именем. В магазине представлено
        более {formatPrice(30000)} наименований товаров парфюмерно-косметической индустрии, при этом
        ассортимент постоянно расширяется.
        </p>
        <h3 className={styles.title}>
        Наши Поставщики
        </h3>
        <p className={styles.text}>
        Мы работаем только с надежными и проверенными поставщиками парфюмерии и косметики,
        которые являются официальными дистрибьюторами и дилерами оригинальной продукции.
        </p>
        <h3 className={styles.title}>
        Наши Цены
        </h3>
        <p className={styles.text}>
        Интернет-магазин
          <Link
            data-cke-saved-href="https://parfumart.ru/"
            to="/"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.item_link}
          > Parfumart.ru
          </Link> предлагает оригинальную и качественную продукцию по ценам,
        существенно отличающимся от стоимости тех же товаров, представленных в парфюмерных бутиках.
        Связано это с тем, что наши издержки не сопоставимы с издержками крупных розничных магазинов,
        а большинство товаров мы выкупаем специально под заказы наших клиентов, тем самым имея
        возможность не содержать большой товарный запас.
        </p>
        <h3 className={styles.title}>
        Наши Гарантии
        </h3>
        <p className={styles.text}>
        Интернет-магазин
          <Link
            data-cke-saved-href="https://parfumart.ru/"
            to="/"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.item_link}
          > Parfumart.ru
          </Link> осуществляет удаленную торговлю в соответствии с законодательством
        РФ и гарантирует защиту прав потребителя. В связи с этим, мы предлагаем публичную оферту для наших
        клиентов. Ознакомиться подробнее с условиями публичной оферты Вы можете, пройдя по <Link
            to="/info/publichnaya-oferta"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.item_link}
          > ссылке.
          </Link>
        </p>
        <h3 className={styles.title}>
        Наша Миссия
        </h3>
        <p className={styles.text}>
        Миссией нашей компании является удовлетворение потребности широчайшего круга клиентов в приобретении
        парфюмерии и косметики наивысшего качества, с минимальными затратами времени и денег.
        </p>
      </div>
    </InfoPage>
  );
};

export default AboutUs;
