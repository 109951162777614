import MarkerIcon from "assets/profile/icons/MarkerIcon";
import CitiesSuggestionContent from "components/CitiesSuggestionContent/CitiesSuggestionContent";
import SelectCityModal from "components/SelectCityModal/SelectCityModal";
import cn from "classnames";
import styles from "./styles.module.scss";

interface ICitySelectProps {
  currentCity: string;
  setCurrentCity: (city: string) => Promise<void>;
  cityModalRef: React.MutableRefObject<HTMLDivElement | null>;
  selectCitiesModalRef: React.MutableRefObject<HTMLDivElement | null>;
  setIsOpenCityModal: (value: React.SetStateAction<boolean>) => void;
  setShowSelectCities: (value: React.SetStateAction<boolean>) => void;
  isOpenCityModal: boolean;
  showSelectCities: boolean;
  onCloseModals: () => void;
}

const CitySelect: React.FC<ICitySelectProps> = ({
  currentCity,
  setCurrentCity,
  cityModalRef,
  selectCitiesModalRef,
  setIsOpenCityModal,
  setShowSelectCities,
  isOpenCityModal,
  showSelectCities,
  onCloseModals
}) => {
  return (
    <div className={styles["city-select"]}>
      <MarkerIcon width={16} height={20} className={styles["marker-icon"]} />
      <div className={styles["city-select__info"]}>
        <button
          className={cn(styles["city-select__info_button"], {
            [styles["city-select__info_button--selected"]]: !!currentCity
          })}
          onClick={() => setIsOpenCityModal(!isOpenCityModal)}
        >
          {!currentCity ? "Выбор города" : currentCity}
        </button>
        {isOpenCityModal && (
          <CitiesSuggestionContent
            cityTitle={currentCity}
            containerStyles={cn(styles["cities-suggestion-modal"], {
              [styles["cities-suggestion-modal--shadow-disabled"]]:
                showSelectCities
            })}
            contentRef={cityModalRef}
            onClose={() => setIsOpenCityModal(false)}
            onShowSelectCities={() => setShowSelectCities(true)}
          />
        )}
        {showSelectCities && (
          <SelectCityModal
            modalRef={selectCitiesModalRef}
            onClose={() => setShowSelectCities(false)}
            onCloseCb={onCloseModals}
            setCity={setCurrentCity}
          />
        )}
      </div>
    </div>
  );
};

export default CitySelect;
