import { Link } from "react-router-dom";
import InfoPage from "../InfoPage";
import SectionTitle from "../../../components/SectionTitle/SectionTitle";
import pageInfo from "../pageInfo.json";

import styles from "./styles.module.scss";
import { getCanonicalUrlForInfoPage } from "utils/helpers/getCanonicalUrl";

const PublicOffering: React.FC = () => {
  const publicOfferingData = pageInfo.publicOffer;
  const canonicalUrl = getCanonicalUrlForInfoPage(window.location.href);

  return (
    <InfoPage metaData={publicOfferingData} canonicalUrl={canonicalUrl}>
      <h1 className={styles.seo}>Инфо страница Публичная оферта</h1>
      <SectionTitle
        title="Публичная оферта"
        containerStyles={styles["custom-title"]}
      />
      <div className={styles.root}>
        <div className={styles.preview}>
          Внимательно
          ознакомьтесь с текстом публичной оферты и, если Вы не согласны с
          каким-либо пунктом оферты, Вы вправе отказаться от покупки Товаров,
          предоставляемых Продавцом, и не совершать действий, направленных на
          заказ и приобретение Товара. Начало совершения действий, направленных
          на приобретение Товаров у Продавца, подразумевает Ваше согласие со
          всеми условиями Оферты.
        </div>
        <div className={styles.info}>
          <h3 className={styles.title}>
            Договор-oферта интернет-магазина
            <Link
              data-cke-saved-href="https://parfumart.ru/"
              to="/"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.item_link}
            >
              {" "}
              www.parfumart.ru
            </Link>
          </h3>
        </div>
        <div className={styles.info}>
          <h3 className={styles.title}>Термины</h3>
          <ul className={styles.info__list}>
            <li>
              <span className={styles["strong-text"]}>&quot;Оферта&quot;</span>{" "}
              - публичное предложение Продавца, адресованное любому физическому
              лицу (гражданину), заключить с ним договор купли-продажи (далее -
              &quot;Договор&quot;) на существующих условиях, содержащихся в
              Договоре, включая все его приложения.
            </li>
            <li>
              <span className={styles["strong-text"]}>
                &quot;Продавец&quot;
              </span>{" "}
              - ИП Молина Светлана Сергеевна
            </li>
            <li>
              <span className={styles["strong-text"]}>
                &quot;Покупатель&quot;
              </span>{" "}
              - полностью дееспособное физическое лицо, размещающее Заказы на
              сайте
              <Link
                data-cke-saved-href="https://parfumart.ru/"
                to="/"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.item_link}
              >
                {" "}
                www.parfumart.ru
              </Link>
              , либо указанное в качестве получателя Товара, либо использующее
              Товары, приобретенные на сайте
              <Link
                data-cke-saved-href="https://parfumart.ru/"
                to="/"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.item_link}
              >
                {" "}
                www.parfumart.ru
              </Link>
              , исключительно для личных, семейных, домашних и иных нужд, не
              связанных с осуществлением предпринимательской деятельности.
            </li>
            <li>
              <span className={styles["strong-text"]}>
                &quot;Интернет-магазин&quot;
              </span>{" "}
              - интернет-сайт, принадлежащий Продавцу, расположенный на сервере
              в г. Москва и имеющий адрес в сети Интернет
              <Link
                data-cke-saved-href="https://parfumart.ru/"
                to="/"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.item_link}
              >
                {" "}
                https://parfumart.ru
              </Link>
              . На нем представлены товары, предлагаемые Продавцом своим
              Покупателям для оформления Заказов, а также условия оплаты,
              доставки, возврата и обмена товаров.
            </li>
            <li>
              <span className={styles["strong-text"]}>&quot;Сайт&quot;</span> -
              <Link
                data-cke-saved-href="https://parfumart.ru/"
                to="/"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.item_link}
              >
                {" "}
                www.parfumart.ru
              </Link>
            </li>
            <li>
              <span className={styles["strong-text"]}>&quot;Товар&quot;</span> -
              объект материального мира, не изъятый из гражданского оборота и
              представленный к продаже на Сайте.
            </li>
            <li>
              <span className={styles["strong-text"]}>&quot;Заказ&quot;</span> –
              должным образом оформленный запрос Покупателя на приобретение и
              доставку по указанному адресу перечня Товаров, представленного на
              Сайте.
            </li>
            <li>
              <span className={styles["strong-text"]}>
                &quot;Служба доставки&quot;
              </span>{" "}
              – штатный курьер интернет-магазина, или третье лицо, или компания,
              оказывающее по договору с Продавцом услуги по доставке Заказов
              Покупателям.
            </li>
          </ul>
        </div>
        <div className={styles.rules}>
          <ol>
            <li>
              <h3 className={styles.title}>Предмет Оферты</h3>
              <ol>
                <li>
                  <p>
                    Предметом настоящего Соглашения является предоставление
                    возможности Пользователю приобретать для личных, семейных,
                    домашних и иных нужд, не связанных с осуществлением
                    предпринимательской деятельности, Товары, представленные в
                    каталоге Интернет-магазина по адресу
                    <Link
                      data-cke-saved-href="https://parfumart.ru/"
                      to="/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.item_link}
                    >
                      {" "}
                      https://parfumart.ru
                    </Link>
                  </p>
                </li>
                <li>
                  <p>
                    Данная Оферта распространяется на все виды Товаров и услуг,
                    представленных на Сайте, пока такие предложения с описанием
                    присутствуют в каталоге Интернет-магазина.
                  </p>
                </li>
                <li>
                  <p>
                    Настоящие Условия, также информация о Товаре, представленная
                    на Сайте, являются публичной офертой в соответствии со
                    ст.435 и ч.2 ст.437 ГК РФ
                  </p>
                </li>
                <li>
                  <p>
                    Настоящая публичная Оферта определяет все существенные
                    условия договора между ИП Молиной С.С. и лицом, принимающим
                    Оферту (далее – Соглашение)
                  </p>
                </li>
                <li>
                  <p>
                    Покупатель безоговорочно принимает условия Соглашения
                    посредством оформления Заказа на Сайте или через оператора
                    по телефону, указанному на Сайте, после чего Продавец и
                    Покупатель вступают в договорные отношения
                  </p>
                </li>
                <li>
                  <p>
                    Сделка считается совершенной, в момент окончательного
                    подтверждения заказа Покупателем, подтверждения цены и
                    наличия товара Продавцом, при этом Продавец обязуется
                    передать в собственность Покупателю, а Покупатель обязуется
                    оплатить и принять заказанные в интернет-магазине
                    <Link
                      data-cke-saved-href="https://parfumart.ru/"
                      to="/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.item_link}
                    >
                      {" "}
                      https://parfumart.ru
                    </Link>{" "}
                    Товары
                  </p>
                </li>
                <li>
                  <p>
                    Условия Оферты могут быть изменены Продавцом в одностороннем
                    порядке без уведомления Покупателя. Новая редакция
                    Соглашения вступает в силу по истечении 10 (Десяти)
                    календарных дней с момента ее опубликования на Сайте, если
                    иное не предусмотрено условиями настоящего Соглашения.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <h3 className={styles.title}>Регистрация на Сайте</h3>
              <ol>
                <li>
                  <p>
                    Регистрация на Сайте осуществляется по адресу
                    <Link
                      data-cke-saved-href="https://parfumart.ru/"
                      to="/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.item_link}
                    >
                      {" "}
                      https://parfumart.ru
                    </Link>{" "}
                    через форму регистрации
                  </p>
                </li>
                <li>
                  <p>
                    Для оформления Заказа Покупателю необязательно
                    регистрироваться на Сайте. Регистрация предлагается в
                    качестве бесплатного сервиса, способствующего удобству
                    оформления заказов и отслеживанию истории покупок Покупателя
                  </p>
                </li>
                <li>
                  <p>
                    Продавец не несет ответственности за точность и правильность
                    информации, предоставляемой Покупателем при регистрации
                  </p>
                </li>
                <li>
                  <p>
                    Продавец обязуется не использовать личные данные Покупателя,
                    указанные при регистрации, кроме как в целях исполнения
                    своих обязательств по исполнению Заказа
                  </p>
                </li>
                <li>
                  <p>
                    Покупатель обязуется не сообщать третьим лицам логин и
                    пароль, указанные при регистрации. В случае возникновения у
                    Покупателя подозрений относительно безопасности его логина и
                    пароля или возможности их несанкционированного использования
                    третьими лицами, Покупатель обязуется незамедлительно
                    уведомить об этом Продавца, направив электронное письмо на
                    адрес электронной почты{" "}
                    <a
                      href="mailto:client@parfumart.ru"
                      rel="noopener noreferrer"
                      className={styles.item_link}
                    >
                      client@parfumart.ru
                    </a>
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <h3 className={styles.title}>
                Товар и порядок оформления заказа
              </h3>
              <ol>
                <li>
                  <p>
                    Все информационные материалы, представленные на Сайте, носят
                    справочный характер и не могут в полной мере передавать
                    достоверную информацию о свойствах и характеристиках Товара,
                    включая цвет, размер и форму. В случае возникновения у
                    Покупателя вопросов, касающихся свойств и характеристик
                    Товара, перед оформлением Заказа, Покупатель должен
                    обратиться к Продавцу, в Службу поддержки клиентов по
                    телефону, указанному на сайте, или посредством электронной
                    почты
                    <a
                      href="mailto:client@parfumart.ru"
                      rel="noopener noreferrer"
                      className={styles.item_link}
                    >
                      {" "}
                      client@parfumart.ru
                    </a>
                  </p>
                </li>
                <li>
                  <p>
                    Вся продукция сертифицирована и полностью соответствует всем
                    нормам и стандартам качества, подтверждённая
                    соответствующими документами (Сертификатом соответствия либо
                    Декларацией о соответствии)
                  </p>
                </li>
                <li>
                  <p>
                    Заказ Покупателя может быть оформлен следующими способами:
                    принят по телефону, указанному на Сайте Продавца, или
                    оформлен Покупателем самостоятельно на Сайте. Подробности
                    оформления Заказа описаны в разделе &quot;Как заказать&quot;
                    по адресу:
                    <Link
                      data-cke-saved-href="https://parfumart.ru/info/kak-zakazat/"
                      to="/info/kak-zakazat/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.item_link}
                    >
                      {" "}
                      https://parfumart.ru/info/kak-zakazat/
                    </Link>
                  </p>
                </li>
                <li>
                  <p>
                    При оформлении Заказа Покупателю необходимо указать
                    следующую информацию: Ф.И.О. Покупателя либо получателя
                    Заказа; адрес доставки Заказа (индекс обязателен только для
                    доставки в любой другой населенный пункт, кроме города
                    Краснодара); контактный телефон
                  </p>
                </li>
                <li>
                  <p>
                    После оформления заказа Покупателю предоставляется
                    информация об ожидаемой дате и времени доставки Заказа
                    получателю посредством телефонной или электронной связи.
                    Указанные дата и время зависит от наличия заказанных Товаров
                    на складе Продавца и времени, необходимого на обработку
                    Заказа
                  </p>
                </li>
                <li>
                  <p>
                    Ожидаемая дата передачи Заказа в Службу доставки сообщается
                    Покупателю менеджером, обслуживающим Заказ, по электронной
                    почте, смс и/или при контрольном звонке Покупателю
                  </p>
                </li>
                <li>
                  <p>
                    Если Покупателем оформлен Заказ на Товар, который Продавец
                    не может предоставить в связи с его отсутствием на складе,
                    Продавец информирует об этом Покупателя посредством
                    телефонной или электронной связи. Покупатель вправе
                    согласиться принять Товар в количестве, имеющемся в наличии
                    у Продавца, либо аннулировать данную позицию Товара из
                    Заказа. В случае неполучения ответа Покупателя в течение 2
                    рабочих дней, Продавец оставляет за собой право аннулировать
                    данный Товар из Заказа
                  </p>
                </li>
                <li>
                  <p>
                    В случае, если предоплаченный Заказ аннулирован полностью
                    либо частично, стоимость аннулированного Заказа (или
                    нескольких Товаров из Заказа) возвращается Продавцом
                    Покупателю посредством банковского переводы по реквизитам,
                    указанным Покупателем
                  </p>
                </li>
                {/* <li>
                  <p>
                  Если Покупателем оформлен Заказ на Товар из категории «винтаж»,
                  то исполнение такого Заказа возможно только после 20%-ой предоплаты общей стоимости Заказа
                  </p>
                </li> */}
                {/* <li>
                  <p>
                    Если Покупателем оформлен Заказ с доставкой за пределы
                    города Краснодара, в котором общая стоимость Товаров не
                    превышает 1000 (одной тысячи) рублей или же стоимость
                    Доставки превышает 50% от общей стоимости Товаров, то
                    исполнение такого Заказа возможно лишь при его 100%-ой
                    предоплате
                  </p>
                </li> */}
                {/* <li>
                  <p>
                    Если Покупателем оформлен заказ на Товар с пометкой
                    «распродажа» с доставкой за пределы города Краснодара, то
                    такой заказ может быть исполнен только после 100% предоплаты
                  </p>
                </li> */}
                {/* <li>
                  <p>
                    В случае, если предоплаченный Заказ аннулирован полностью
                    либо частично, стоимость аннулированного Заказа (или
                    нескольких Товаров из Заказа) возвращается Продавцом
                    Покупателю тем же способом, которым данные Товары были
                    оплачены
                  </p>
                </li> */}
                <li>
                  <p>
                    Продавец вправе аннулировать Заказы Покупателя, содержащие
                    Товары, от которых ранее Покупатель отказался, указав
                    причины, не связанные с наличием недостатков в этих Товарах
                    (или не указав причины вовсе)
                  </p>
                </li>
                <li>
                  <p>
                    Продавец вправе отказать в оформлении Заказа, если у
                    Покупателя уже оформлены и не получены другие Заказы или
                    попросить внести предоплату в размере 100% стоимости Заказа
                  </p>
                </li>
                <li>
                  <p>
                    Продавец вправе изменить условия исполнения Заказа на свое
                    усмотрение
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <h3 className={styles.title}>Доставка Товара</h3>
              <ol>
                <li>
                  <p>
                    Способы доставки Товаров указаны на Сайте в разделе
                    «Доставка» по адресу:
                    <Link
                      data-cke-saved-href="https://parfumart.ru/info/dostavka/"
                      to="/info/dostavka/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.item_link}
                    >
                      {" "}
                      https://parfumart.ru/info/dostavka/
                    </Link>
                  </p>
                </li>
                <li>
                  <p>
                    Территория доставки Товаров, представленных на Сайте,
                    ограничена пределами Российской Федерации и СНГ
                  </p>
                </li>
                <li>
                  <p>
                    Доставка Товара Покупателю осуществляется по адресу и в
                    сроки, согласованные Покупателем и Продавцом при оформлении
                    заказа, либо Покупатель самостоятельно забирает Товар(ы) из
                    пункта самовывоза Продавца
                  </p>
                </li>
                <li>
                  <p>
                    Срок поставки Товара в пункт самовывоза не превышает 7
                    (семи) календарных дней
                  </p>
                </li>
                <li>
                  <p>
                    Сроки доставки, указанные в интернет-магазине (раздел
                    &quot;Доставка&quot;), указаны в ознакомительных
                    целях и не учитывают непредвиденных обстоятельств со стороны
                    служб доставки
                  </p>
                </li>
                <li>
                  <p>
                    Стоимость доставки каждого Заказа рассчитывается
                    индивидуально, исходя из его веса, региона и способа
                    доставки, и указывается на последнем этапе оформления Заказа
                    на Сайте
                  </p>
                </li>
                {/* <li>
                  <p>
                    Доставка заказов, стоимость которых не превышает 1000
                    рублей, в другие города и регионы, кроме города Краснодара,
                    осуществляется только по 100%-ой предоплате
                  </p>
                </li> */}
                <li>
                  <p>
                    Доставка Товара осуществляется штатным курьером
                    интернет-магазина или транспортными компаниями,
                    перевозчиками
                  </p>
                </li>
                <li>
                  <p>
                    При доставке Заказ вручается Покупателю либо лицу,
                    указанному в качестве получателя Заказа. При невозможности
                    получения Заказа, оформленного за наличный расчет,
                    указанными выше лицами, Заказ вручается лицу, готовому
                    предоставить сведения о заказе (номер отправления и/или ФИО
                    получателя), а также оплатить стоимость Заказа в полном
                    объеме лицу, осуществляющему доставку Заказа
                  </p>
                </li>
                <li>
                  <p>
                    Во избежание случаев мошенничества, а также для выполнения
                    взятых на себя обязательств в пункте 4.8., при вручении
                    предоплаченного Заказа лицо, осуществляющее доставку Заказа,
                    вправе затребовать документ, удостоверяющий личность
                    получателя. Продавец гарантирует конфиденциальность и защиту
                    персональной информации получателя (п.9.3.)
                  </p>
                </li>
                <li>
                  <p>
                    Риск случайного повреждения Товара переходит к Покупателю с
                    момента передачи ему Заказа и проставления получателем
                    Заказа подписи в документах, подтверждающих доставку Заказа.
                    В случае недоставки Заказа, Продавец возмещает Покупателю
                    стоимость предоплаченного Заказа и доставки, после получения
                    подтверждения утраты Заказа от Службы Доставки или
                    транспортной компании/перевозчика
                  </p>
                </li>
                <li>
                  <p>
                    Продавец не несет ответственности за сроки доставки и (или)
                    неполучение Товара Покупателем в случае предоставления им
                    неверного адреса доставки и (или) почтового индекса, а также
                    неверной фамилии и (или) имени получателя и других
                    персональных данных получателя
                  </p>
                </li>
                <li>
                  <p>
                    Сроки, отведенные Продавцом для получения Заказа Покупателем
                    путем самовывоза, ограничены. С момента подтверждения
                    Заказа, Товар резервируется на имя Покупателя на срок в 5
                    рабочих дней. Неполучение Заказа в указанный срок считается
                    отказом Покупателя от Соглашения и является основанием для
                    аннулирования Заказа Продавцом
                  </p>
                </li>
                <li>
                  <p>
                    Обязанность Продавца передать товар Покупателю считается
                    исполненной в момент вручения курьером Товара Покупателю
                    (получателю) или получения Товара Покупателем (получателем)
                    в пункте самовывоза транспортной компании. При получении
                    Заказа в пункте самовывоза транспортной компании,
                    Покупатель, после оплаты доставленного Товара, обязан
                    осмотреть доставленный Товар и произвести его вскрытие в
                    присутствии работников транспортной компании для проверки
                    Товара на соответствие заявленному количеству, ассортименту
                    и комплектности Товара, а также проверить срок службы
                    доставленного Товара и целостность упаковки. В случае
                    наличия претензий к доставленному Товару (недокомплект;
                    наличие Товара; несоответствующего указанному в описи
                    отправления; производственный брак; иные претензии) по
                    указанию Покупателя работниками транспортной компании
                    составляется Акт о выявленных несоответствиях. Если
                    Покупателем не были заявлены претензии в вышеуказанном
                    порядке, то Продавец считается полностью и надлежащим
                    образом, исполнившим свои обязательства по Соглашению. В
                    случае возврата доставленного Товара посредством
                    транспортной компании, в связи с наличием претензий к
                    Товару, Покупатель обязан приложить к отправлению,
                    содержащему возвращаемый Товар, следующие документы:
                  </p>
                  <ul className={styles["list--markered"]}>
                    <li className={styles["list_item--markered"]}>
                      заявление на возврат денежных средств по образцу;
                    </li>
                    <li className={styles["list_item--markered"]}>
                      копию акта о выявленных несоответствиях;
                    </li>
                    <li className={styles["list_item--markered"]}>
                      копию квитанции об оплате;
                    </li>
                    <li className={styles["list_item--markered"]}>
                      копию описи отправления;
                    </li>
                  </ul>
                </li>
                <li>
                  <p>
                    При принятии Заказа от курьера, Покупатель (или получатель)
                    обязан осмотреть доставленный Товар и проверить его на
                    соответствие заявленному количеству, ассортименту и
                    комплектности Товара, а также проверить срок службы
                    доставленного Товара и целостность упаковки. В случае
                    отсутствия претензий к доставленному Товару Покупатель
                    расписывается в «Бланке доставки заказов» и оплачивает Заказ
                    (в случае отсутствия 100%-ной предоплаты). Подпись в
                    доставочных документах свидетельствует о том, что претензий
                    к Товару Покупателем не заявлено и Продавец полностью и
                    надлежащим образом выполнил свою обязанность по передаче
                    Товара. Время нахождения курьера по адресу Покупателя
                    ограничено 15 минутами. Приемка товара без замечаний в
                    момент фактической передачи товара (в том числе нереализация
                    Покупателем прав на осмотр при приемке Товара) лишает
                    Покупателя права ссылаться на:
                  </p>
                  <ul className={styles["list--markered"]}>
                    <li className={styles["list_item--markered"]}>
                      несоответствие количества фактически поставленного Товара
                      Заказу или иному сопроводительному документу;
                    </li>
                    <li className={styles["list_item--markered"]}>
                      недостаточность/неполноту комплектации Товара;
                    </li>
                    <li className={styles["list_item--markered"]}>
                      несоответствие Товара по ассортименту;
                    </li>
                    <li className={styles["list_item--markered"]}>
                      общая целостность Товара и упаковки, некорректная работа
                      распылителя, наличие на Товаре потертостей, пятен,
                      разводов, а также иных недостатков, для обнаружения
                      которых не требуется применение специального оборудования;
                    </li>
                  </ul>
                  <p>
                    Товар считается принятым без претензий по количеству,
                    качеству и комплектности, что не лишает Покупателя
                    возможности реализации права предъявления требований в
                    соответствии с действующим законодательством, в том числе
                    Законом «О защите прав потребителей» от 07.02.1992 № 2300-1
                  </p>
                </li>
                <li>
                  <p>
                    Пользователь понимает и соглашается с тем, что:
                    осуществление доставки - отдельная услуга, не являющаяся
                    неотъемлемой частью приобретаемого Покупателем Товара,
                    выполнение которой заканчивается в момент получения
                    Покупателем Товара и осуществления платежа за него.
                    Претензии к качеству приобретенного Товара, возникшие после
                    получения и оплаты Товара, рассматриваются в соответствии с
                    Законом РФ «О защите прав потребителей» и гарантийными
                    обязательствами Продавца. В связи с этим приобретение Товара
                    с доставкой не дает Покупателю право требования доставки
                    приобретенного Товара в целях гарантийного обслуживания или
                    замены, не дает возможности осуществлять гарантийное
                    обслуживание или замену Товара посредством выезда к
                    Покупателю и не подразумевает возможность возврата стоимости
                    доставки Товара в случаях, когда Покупатель имеет право на
                    возврат денег за Товар как таковой, в соответствии с Законом
                    РФ «О защите прав потребителей».
                  </p>
                </li>
                <li>
                  <p>
                    Неявка Покупателя или несовершение оплаты доставки, иных
                    необходимых действий для принятия Товара могут
                    рассматриваться Продавцом в качестве отказа Покупателя от
                    исполнения Договора. Продавец вправе истребовать с
                    Покупателя возмещение стоимости доставки от Продавца до
                    Покупателя (получателя)
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <h3 className={styles.title}>Цена Товара</h3>
              <ol>
                <li>
                  <p>
                    Цены в интернет-магазине
                    <Link
                      data-cke-saved-href="https://parfumart.ru/"
                      to="/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.item_link}
                    >
                      {" "}
                      parfumart.ru
                    </Link>{" "}
                    указаны в рублях РФ
                  </p>
                </li>
                <li>
                  <p>
                    Общая стоимость заказа отображается в разделе сайта
                    «Корзина» в строке «Всего к оплате»
                  </p>
                </li>
                <li>
                  <p>
                    В случае неверного указания цены Товара, заказанного
                    Покупателем и/или сведений о его наличии (вследствие
                    технического сбоя), Продавец, при первой возможности,
                    информирует об этом Покупателя для подтверждения Заказа по
                    исправленной цене либо аннулирования Заказа. При
                    невозможности связаться с Покупателем, данный Заказ
                    считается аннулированным. Если Заказ был оплачен, Продавец
                    возвращает Покупателю оплаченную сумму в полном объеме тем
                    же способом, каким Товар был оплачен
                  </p>
                </li>
                <li>
                  <p>
                    Окончательная Цена Товара, с учетом возможной скидки,
                    указывается на последнем этапе оформления Заказа и
                    действительна на момент нажатия кнопки &quot;Купить&quot;.
                    При этом цена на заказанный Клиентом Товар изменению не
                    подлежит, если не возникают обстоятельства, описанные в п.
                    5.3.
                  </p>
                </li>
                <li>
                  <p>
                    Цена Товара может быть изменена Продавцом в одностороннем
                    порядке. При этом цена на уже заказанный и подтвержденный
                    менеджером интернет-магазина
                    <Link
                      data-cke-saved-href="https://parfumart.ru/"
                      to="/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.item_link}
                    >
                      {" "}
                      Parfumart.ru
                    </Link>{" "}
                    Товар изменению не подлежит
                  </p>
                </li>
                <li>
                  <p>
                    Продавец вправе устанавливать скидки в целях продвижения
                    того либо иного способа оплаты или доставки Товара. При этом
                    Продавец может ограничивать условия действия скидок на свое
                    усмотрение.
                  </p>
                </li>
                <li>
                  <p>
                    Скидка постоянного клиента суммируется со всеми акционными
                    предложениями на сайте, кроме товаров из категории
                    «Распродажа». Все товары данной категории имеют
                    соответствующую метку. Цена таких товаров является
                    окончательной и изменению не полежит
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <h3 className={styles.title}>Оплата Товара</h3>
              <ol>
                <li>
                  <p>
                    Продажа товаров из категории &quot;винтаж&quot;
                    осуществляется только по предоплате в размере 20% от
                    стоимости товара. Только после получения предоплаты
                    запускается процесс исполнения заказа. При этом Продавец, в
                    обязательном порядке, предоставляет фото товара Покупателю,
                    получает согласие Покупателя на поставку товара, затем
                    исполняет заказ. После поступления товара в пункт
                    самовывоза, Покупатель проверяет его комплектацию,
                    качественное соответствие, после чего оплачивает оставшуюся
                    сумму
                  </p>
                </li>
                <li>
                  <p>
                    Товары с пометкой «распродажа» и доставкой за пределы города
                    Краснодара требуют 100% предоплаты
                  </p>
                </li>
                <li>
                  <p>
                    Если заказ поступает из другого региона РФ, при этом
                    стоимость доставки в указанный регион превышает 50%
                    стоимости заказа, то исполнение заказа осуществляется только
                    по 100% предоплате
                  </p>
                </li>
                <li>
                  <p>
                    При наличной форме оплаты Покупатель обязан уплатить
                    Продавцу цену Товара в момент его приема-передачи, а
                    Продавец обязан предоставить Покупателю кассовый или
                    товарный чек, или иной документ, подтверждающий оплату
                    Товара
                  </p>
                </li>
                <li>
                  <p>
                    В соответствии с положением ЦБ РФ &quot;Об эмиссии
                    банковских карт и об операциях, совершаемых с использованием
                    платежных карт&quot; от 24.12.2004 №266-П операции по
                    банковским картам совершаются держателем карты либо
                    уполномоченным им лицом
                  </p>
                </li>
                <li>
                  <p>
                    При совершении оплаты Товара онлайн с помощью банковской
                    карты, в момент получения Заказа Покупатель (получатель)
                    должен предъявить документ, удостоверяющий личность
                  </p>
                </li>
                <li>
                  <p>
                    Авторизация операций по банковским картам осуществляется
                    банком. Если у банка есть основания полагать, что операция
                    носит мошеннический характер, то банк вправе отказать в
                    осуществлении данной операции. Мошеннические операции с
                    банковскими картами попадают под действие статьи 159 УК РФ
                  </p>
                  {/* <ul>
                    <li>
                      <p>
                        а) при оплате заказа картой рассрочки скидка постоянного
                        клиента не применяется ни при каких обстоятельствах;
                      </p>
                    </li>
                    <li>
                      <p>
                        б) торговые предложения из категории «Распродажа»
                        являются недействительными при оплате картами рассрочки,
                        стоимость товаров подлежит пересмотру и подтверждению
                        сотрудниками
                        <Link
                          data-cke-saved-href="https://parfumart.ru/"
                          to="/"
                          target="_blank"
                          rel="noopener noreferrer"
                          className={styles.item_link}
                        >
                          {" "}
                          Parfumart.ru
                        </Link>
                        .
                      </p>
                    </li>
                  </ul> */}
                </li>
                <li>
                  <p>
                    Во избежание случаев различного рода неправомерного
                    использования банковских карт при оплате, все Заказы,
                    оформленные на Сайте и предоплаченные банковской картой,
                    проверяются Продавцом. Продавец оставляет за собой право без
                    объяснения причины аннулировать Заказ. Стоимость Заказа
                    возвращается на банковскую карту владельца в полном объеме.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <h3 className={styles.title}>
                Обмен и возврат Товара и денежных средств
              </h3>
              <ol>
                {/* <li>
                  <p>
                    Возврат Товара осуществляется в соответствии с «Условиями
                    возврата», указанными на Сайте по адресу:
                    <Link
                      data-cke-saved-href="https://parfumart.ru/info/vozvrat-i-obmen"
                      to="/info/vozvrat-i-obmen"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.item_link}
                    >
                      {" "}
                      https://parfumart.ru/info/vozvrat-i-obmen/
                    </Link>
                  </p>
                </li> */}
                <li>
                  Возврат Товара надлежащего качества: <br />
                  Парфюмерно-косметические товары обмену и возврату не подлежат
                  (Постановление Правительства РФ от 31.12.2020 N 2463 &quot;Об
                  утверждении Правил продажи товаров по договору розничной
                  купли-продажи, перечня товаров длительного пользования, на
                  которые не распространяется требование потребителя о
                  безвозмездном предоставлении ему товара, обладающего этими же
                  основными потребительскими свойствами, на период ремонта или
                  замены такого товара, и перечня непродовольственных товаров
                  надлежащего качества, не подлежащих обмену, а также о внесении
                  изменений в некоторые акты Правительства Российской
                  Федерации&quot;).
                  <ol>
                    <li>
                      Покупатель вправе отказаться от приобретения Товара до
                      момента его получения
                    </li>
                    <li>
                      <p>
                        Покупатель-потребитель вправе отказаться от Товара
                        надлежащего качества в течение 7 (семи) дней со дня его
                        передачи, если Товар не был в употреблении, сохранены
                        его потребительские свойства, товарный вид, оригинальная
                        фабричная упаковка, ярлыки, а также приложены документы,
                        подтверждающие факт покупки на сайте{" "}
                        <Link
                          data-cke-saved-href="https://parfumart.ru/"
                          to="/"
                          target="_blank"
                          rel="noopener noreferrer"
                          className={styles.item_link}
                        >
                          {" "}
                          https://parfumart.ru
                        </Link>{" "}
                        . Если документ не сохранен, можно предъявить другие
                        доказательства покупки. Покупатель составляет заявление
                        о возврате Товара, с указанием своего полного имени,
                        номера и даты заказа, даты оплаты и даты передачи
                        Товара. При отказе Покупателя от Товара согласно п.
                        7.1.2, Продавец возвращает ему стоимость Товара, за
                        исключением расходов Продавца, связанных с передачей и
                        возвратом Товара. Расходы по возврату Товара надлежащего
                        качества после его передачи Покупателю несет Покупатель.
                      </p>
                    </li>
                    {/* <li>
                      <p>
                        Вернуть или обменять можно только Товар надлежащего
                        качества, который не был в употреблении, сохранены его
                        потребительские свойства, товарный вид, оригинальная
                        фабричная упаковка, ярлыки, а также приложены документы,
                        подтверждающие факт покупки на сайте
                        <Link
                          data-cke-saved-href="https://parfumart.ru/"
                          to="/"
                          target="_blank"
                          rel="noopener noreferrer"
                          className={styles.item_link}
                        >
                          {" "}
                          https://parfumart.ru
                        </Link>{" "}
                        Парфюмерно-косметические продукты входят в перечень
                        невозвратных товаров. Ознакомиться с полным перечнем
                        непродовольственных товаров надлежащего качества, не
                        подлежащих возврату или обмену на аналогичный товар
                        можно в разделе «Возврат и Обмен» по адресу:
                        <Link
                          data-cke-saved-href="https://parfumart.ru/info/vozvrat-i-obmen"
                          to="/info/vozvrat-i-obmen"
                          target="_blank"
                          rel="noopener noreferrer"
                          className={styles.item_link}
                        >
                          {" "}
                          https://parfumart.ru/info/vozvrat-i-obmen/
                        </Link>
                      </p>
                    </li> */}
                    {/* <li>
                      <p>
                        При отказе Покупателя от Товара согласно п. 7.2,
                        Продавец возвращает ему стоимость возвращенного Товара,
                        за исключением расходов Продавца, связанных с передачей
                        и возвратом возвращенного Товара до Транспортной
                        компании, в случае, если передача уже была осуществлена.
                        Возврат осуществляется тем же способом, каким была
                        произведена оплата, в течение 10 дней с даты поступления
                        возвращенного Товара на склад Продавца вместе с
                        заполненным Покупателем заявлением на возврат,
                        находящемся на сайте в разделе «Возврат»
                        <Link
                          data-cke-saved-href="https://parfumart.ru/info/vozvrat-i-obmen"
                          to="/info/vozvrat-i-obmen"
                          target="_blank"
                          rel="noopener noreferrer"
                          className={styles.item_link}
                        >
                          {" "}
                          https://parfumart.ru/info/vozvrat-i-obmen/
                        </Link>
                      </p>
                    </li> */}
                    {/* <li>
                      <p>
                        Для г. Краснодара. При получении заказа необходимо
                        проверить соответствие комплектации Заказа. Для проверки
                        качества продукции Покупатель вправе вскрывать упаковку
                        продуктов при курьере, а при наличии претензий к
                        качеству Товара, Товар может быть возвращен курьеру. При
                        нарушении целостности упаковки Товара, после ухода
                        курьера, вернуть заказ можно только в случае
                        производственного брака. Курьер не уполномочен давать
                        какие-либо консультации и выполнять поручения клиента.
                        Осуществление доставки – отдельная услуга, не являющаяся
                        неотъемлемой частью приобретаемого покупателем товара.
                        Её выполнение заканчивается в момент получения Клиентом
                        Товара и его оплаты
                      </p>
                    </li> */}
                    {/* <li>
                      <p>
                        Тестеры, пробники, миниатюры и товары из категории
                        «винтаж» обмену и возврату не подлежат
                      </p>
                    </li> */}
                    <li>
                      <p>
                        Если Покупатель обнаружит у Товара недостатки, то вправе
                        предъявить продавцу требования, предусмотренные
                        Гражданским кодексом РФ и иными правовыми актами. В том
                        числе он вправе отказаться от договора, вернуть
                        некачественный Товар продавцу и потребовать возврата
                        уплаченных денег. Покупатель-потребитель также имеет
                        право предъявить требования, предусмотренные Законом РФ
                        &quot;О защите прав потребителей&quot; от 07.02.1992 N
                        2300-1, Правилами продажи товаров по договору розничной
                        купли-продажи, утвержденными Постановлением
                        Правительства РФ от 31.12.2020 N 2463, и иными правовыми
                        актами в сфере защиты прав потребителей. Порядок
                        предъявления требований определяется указанными
                        правовыми актами.
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p>
                    Претензии по качеству Товара направляются в письменном виде
                    на электронный адрес продавца. В претензии необходимо
                    указать полное имя (наименование) Покупателя, номер и дату
                    заказа, дату оплаты и дату передачи Товара, а также описать
                    выявленные недостатки, дату и обстоятельства их обнаружения.
                    К претензии могут быть приложены фотографии.
                  </p>
                </li>
                <li>
                  <p>
                    При возврате Товара (как надлежащего качества, так и с
                    недостатками) Продавец возвращает Покупателю стоимость
                    Товара не позднее чем через 10 (десять) дней с момента,
                    когда Покупатель потребует вернуть деньги на банковские
                    реквизиты, с которых деньги поступили продавцу или которые
                    письменно сообщит Покупатель. Если возвращен качественный
                    Товар, из суммы к возврату вычитается стоимость обратной
                    доставки курьером продавцу возвращенного Товара.
                  </p>
                </li>
                <li>
                  <p>
                    Для заказов, оформленных с доставкой в пределах г.
                    Краснодара. При получении заказа необходимо проверить
                    соответствие комплектации Заказа. Для проверки качества
                    продукции Покупатель вправе вскрывать упаковку продуктов при
                    курьере, а при наличии претензий к качеству Товара, Товар
                    может быть возвращен курьеру. При нарушении целостности
                    упаковки Товара, после ухода курьера, вернуть заказ можно
                    только в случае производственного брака. Курьер не
                    уполномочен давать какие-либо консультации и выполнять
                    поручения клиента. Осуществление доставки – отдельная
                    услуга, не являющаяся неотъемлемой частью приобретаемого
                    Покупателем Товара. Её выполнение заканчивается в момент
                    получения Клиентом Товара и его оплаты
                  </p>
                </li>
                <li>
                  <p>
                    Товары из категории «винтаж», «уценка», а также любые
                    мини-форматы обмену и возврату не подлежат
                  </p>
                </li>
                <li>
                  Возврат товара ненадлежащего качества (брак, повреждения и
                  т.п.)
                  <ol>
                    <li>
                      <p>
                        Под товаром ненадлежащего качества подразумевается
                        товар, который не может обеспечить исполнение своих
                        функциональных качеств. Отличие элементов дизайна или
                        оформления, от заявленных в описании на Сайте, не
                        является признаком ненадлежащего качества или
                        несоответствия заявленному функционалу Товара
                      </p>
                    </li>
                    <li>
                      <p>
                        Внешний вид и комплектность Товара, а также
                        комплектность всего Заказа должны быть проверены
                        Получателем в момент доставки Товара
                      </p>
                    </li>
                    <li>
                      <p>
                        При доставке Товара Покупатель ставит свою подпись в
                        квитанции о доставке в графе: «Товар(-ы) получен,
                        осмотрен, претензий к количеству и внешнем у виду
                        товара(-ов) не имею». После получения Заказа претензии к
                        внешним дефектам товара, его количеству, комплектности и
                        товарному виду не принимаются
                      </p>
                    </li>
                    <li>
                      <p>
                        Требования о возврате уплаченной за Товар денежной суммы
                        подлежат удовлетворению в течение 10 дней со дня
                        предъявления соответствующего требования (ст. 22 Закона
                        РФ «О защите прав потребителей»). При положительном
                        итоге рассмотрения заявления Клиента на возврат денежных
                        средств, возврат уплаченной суммы возвращается тем же
                        способом, каким была произведена оплата Заказа.
                      </p>
                    </li>
                    <li>
                      Возврат Товара осуществляются любым из предложенных
                      способов за счет Клиента:
                      <ul>
                        <li className={styles["list_item--markered"]}>
                          Самостоятельно по адресу: 350059, г. Краснодар, ул.
                          Меланжевая, 10, офис 102
                        </li>
                        <li className={styles["list_item--markered"]}>
                          Посредством транспортной компании или Почты РФ по
                          адресу: 350059, г. Краснодар, ул. Меланжевая, 10, офис
                          102
                        </li>
                      </ul>
                    </li>
                  </ol>
                </li>
                <li>
                  <p>
                    Покупатель подтверждает, что на момент получения Товара
                    информация о порядке и сроках возврата Товара надлежащего
                    качества предоставлена Продавцом в письменной форме путем ее
                    размещения в настоящем договоре-оферте и в момент доставки
                    Товара Покупатель с ней ознакомлен.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <h3 className={styles.title}>
                Ответственность сторон и разрешение споров
              </h3>
              <ol>
                <li>
                  <p>
                    Стороны несут ответственность за неисполнение или
                    ненадлежащее исполнение настоящего Договора в порядке,
                    предусмотренном настоящим Договором и действующим
                    законодательством Российской Федерации
                  </p>
                </li>
                <li>
                  <p>
                    Интернет-магазин и предоставляемые сервисы могут быть временно,
                    частично или полностью недоступны, по причине проведения
                    профилактических или иных работ, а также по любым другим
                    причинам технического характера. Техническая служба Продавца
                    имеет право периодически проводить необходимые
                    профилактические или иные работы с предварительным
                    уведомлением Покупателей или без такового
                  </p>
                </li>
                <li>
                  <p>
                    Продавец не несет ответственности, если ожидания Покупателя
                    о потребительских свойствах Товара оказались не оправданны,
                    в то время как Товар обладает всеми заявленными Продавцом
                    характеристиками
                  </p>
                </li>
                <li>
                  <p>
                    Все споры и разногласия, возникающие при исполнении
                    Сторонами обязательств по настоящему Договору, решаются
                    путем переговоров. В случае невозможности их устранения,
                    Стороны имеют право обратиться за судебной защитой своих
                    интересов
                  </p>
                </li>
                <li>
                  <p>
                    При обращении Покупателя по факту приобретения
                    некачественного товара, Покупатель несет полную материальную
                    ответственность перед Продавцом в том случае, если
                    независимая экспертиза установит умышленное нанесение
                    повреждений или же подтвердит факт неправильной эксплуатации
                    или хранения Товара. В этом случае Покупатель несет расходы
                    за оплату услуг независимой экспертизы и возмещает данные
                    расходы Продавцу
                  </p>
                </li>
                <li>
                  <p>
                    Признание судом недействительности какого-либо положения
                    настоящего Соглашения не влечет за собой недействительность
                    остальных положений
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <h3 className={styles.title}>
                Защита информации, представляемой Покупателем
              </h3>
              <ol>
                <li>
                  <p>
                    Персональные данные Покупателя обрабатывается в соответствии
                    с ФЗ «О персональных данных» No152-ФЗ и Политикой
                    конфиденциальности Продавца
                  </p>
                </li>
                <li>
                  <p>
                    Предоставляя свои персональные данные при регистрации на
                    Сайте, а также при оформлении Заказа, Покупатель соглашается
                    на их обработку Продавцом, в том числе и в целях продвижения
                    Продавцом товаров и услуг
                  </p>
                </li>
                <li>
                  <p>
                    Продавец имеет право отправлять информационные, в том числе
                    рекламные сообщения, на электронную почту и мобильный
                    телефон Покупателя с его согласия. Покупатель вправе
                    отказаться от получения рекламной и другой информации без
                    объяснения причин отказа. Сервисные сообщения, информирующие
                    Покупателя о заказе и этапах его обработки, отправляются
                    автоматически и не могут быть отклонены Покупателем
                  </p>
                </li>
                <li>
                  <p>
                    Продавец вправе использовать технологию &quot;cookies&quot;.
                    &quot;Cookies&quot; не содержат конфиденциальную информацию
                    и не передаются третьим лицам
                  </p>
                </li>
                <li>
                  <p>
                    Продавец получает информацию об ip-адресе посетителя с сайта
                    <Link
                      data-cke-saved-href="https://parfumart.ru/"
                      to="/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.item_link}
                    >
                      {" "}
                      parfumart.ru
                    </Link>
                    . Данная информация не используется для установления
                    личности посетителя
                  </p>
                </li>
                <li>
                  <p>
                    Продавец не несет ответственности за сведения,
                    предоставленные Покупателем на Сайте в общедоступной форме
                    (например, в комментариях и отзывах о Товарах,
                    представленных на Сайте)
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <h3 className={styles.title}>Форс-мажор</h3>
              <ol>
                <li>
                  <p>
                    Стороны освобождаются от ответственности за неисполнение или
                    ненадлежащее исполнение обязательств по Договору на время
                    действия непреодолимой силы. Под непреодолимой силой
                    понимаются чрезвычайные и непреодолимые при данных условиях
                    обстоятельства, препятствующие исполнению своих обязательств
                    Сторонами по настоящему Договору. К ним относятся стихийные
                    явления (землетрясения, наводнения и т. п.), обстоятельства
                    общественной жизни (военные действия, чрезвычайные
                    положения, крупнейшие забастовки, эпидемии и т. п.),
                    запретительные меры государственных органов (запрещение
                    перевозок, валютные ограничения, международные санкции
                    запрета на торговлю и т. п.). В течение этого времени
                    Стороны не имеют взаимных претензий, и каждая из Сторон
                    принимает на себя свой риск последствия форс-мажорных
                    обстоятельств.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <h3 className={styles.title}>Срок действия договора</h3>
              <ol>
                <li>
                  <p>
                    Настоящий Договор вступает в силу с момента обращения в
                    интернет-магазин
                    <Link
                      data-cke-saved-href="https://parfumart.ru/"
                      to="/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.item_link}
                    >
                      {" "}
                      Parfumart.ru
                    </Link>{" "}
                    и оформления Заказа, и заканчивается при полном исполнении
                    обязательств Сторонами
                  </p>
                </li>
                <li>
                  <p>
                    Настоящий Договор может быть расторгнут досрочно по
                    соглашению Сторон
                  </p>
                </li>
                <li>
                  <p>
                    Все, что не урегулировано офертой, определяется в
                    соответствии с законодательством Российской Федерации
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <h3 className={styles.title}>Реквизиты Продавца</h3>
              <ul className={styles.rules__vendor_details}>
                <li className={styles["list_item--markered"]}>
                  Индивидуальный предприниматель Молина Светлана Сергеевна
                </li>
                <li className={styles["list_item--markered"]}>
                  ИНН 234404400711
                </li>
                <li className={styles["list_item--markered"]}>
                  ОГРНИП 321237500361896
                </li>
                <li className={styles["list_item--markered"]}>
                  Email:{" "}
                  <a
                    href="mailto:client@parfumart.ru"
                    className={styles.item_link}
                  >
                    client@parfumart.ru
                  </a>
                </li>
                <li className={styles["list_item--markered"]}>
                  Контактный телефон: +7 (861) 290 21 10
                </li>
                <li className={styles["list_item--markered"]}>
                  Адрес: 350059, Краснодарский край, г. Краснодар, ул.
                  Меланжевая, 10, офис 102
                </li>
                {/* <li className={styles["list_item--markered"]}>
                  Свидетельство о государственной регистрации: серия 23 №
                  008461417
                </li>
                <li className={styles["list_item--markered"]}>
                  Доп. офис № 8619 ПАО «Сбербанк»
                </li>
                <li className={styles["list_item--markered"]}>
                  Расчетный счет 40802810130000009062
                </li>
                <li className={styles["list_item--markered"]}>
                  Корреспондентский счет 30101810100000000602
                </li>
                <li className={styles["list_item--markered"]}>БИК 040349602</li> */}
              </ul>
            </li>
          </ol>
        </div>
      </div>
    </InfoPage>
  );
};

export default PublicOffering;
