import React from "react";
import { NavLink } from "react-router-dom";
import { ProfileRoutesList } from "utils/constants/routes";
import styles from "./styles.module.scss";
import Arrow from "assets/profile/icons/Arrow";

const ProfileRoutes: React.FC = () => {
  return (
    <nav className={styles.navigation}>
      <ul>
        <li>
          <NavLink
            to={ProfileRoutesList.PROFILE}
            className={styles["profile-link"]}
            data-testid="profile-link"
          >
            <span>
              <span className={styles.arrow}>
                <Arrow direction="left" height={14} width={24} />
              </span>
              Личный кабинет
            </span>
          </NavLink>
        </li>
        <div className={styles.separator}></div>
        <li>
          <NavLink
            to={ProfileRoutesList.FAVORITES}
            className={({ isActive }) => (isActive ? styles.active : "")}
            data-testid="favorites-link"
          >
            Избранное
          </NavLink>
        </li>
        <li>
          <NavLink
            to={ProfileRoutesList.MY_ORDERS}
            className={({ isActive }) => (isActive ? styles.active : "")}
            data-testid="my-orders-link"
          >
            Мои заказы
          </NavLink>
        </li>
        <li>
          <NavLink
            to={ProfileRoutesList.PERSONAL_DATA}
            className={({ isActive }) => (isActive ? styles.active : "")}
            data-testid="personal-data-link"
          >
            Личные данные
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default ProfileRoutes;
