import { useMemo } from "react";
import { useAppSelector } from "store/reduxHooks";
import { Link, useNavigate } from "react-router-dom";
import UserIcon from "assets/profile/icons/UserIcon";
import HeartIcon from "assets/profile/icons/HeartIcon";
import SearchIcon from "assets/profile/icons/SearchIcon";
import ShoppingBagIcon from "assets/profile/icons/ShoppingBagIcon";
// import PhoneIcon from "assets/profile/icons/PhoneIcon";
import { ProfileRoutesList } from "utils/constants/routes";
import { useResponsiveIconSizes } from "utils/useResponsiveIconSizes";
import ContactsTabV2 from "pages/Main/components/ContactsTabV2/ContactsTabV2";

import styles from "./styles.module.scss";
import cn from "classnames";

interface IconItem {
  component: React.ReactNode;
  condition: boolean;
  link?: string;
  hasNotification?: boolean;
  notificationColor?: "red" | "gray" | null;
  className: string;
}

const UserBlockV2: React.FC = () => {
  const { user, cart, hasUnviewedFavorites } = useAppSelector(
    (state) => state.user
  );
  const { searchIcon, shoppingBagIcon } = useResponsiveIconSizes();

  const cartNotificationColor = useMemo(() => {
    if (cart.length === 0) return null;
    return cart.every((item) => !item.offer.available) ? "gray" : "red";
  }, [cart]);

  const hasFavorites = user?.favouriteProducts?.length > 0;
  const showFavoritesNotification = hasUnviewedFavorites && hasFavorites;

  const navigate = useNavigate();

  const handleUserIconClick = () => {
    if (user?.phone) {
      navigate("/profile", { replace: true });
    } else {
      navigate("/sign-in", { replace: true });
    }
  };

  const icons: IconItem[] = [
    {
      component: <UserIcon />,
      condition: true,
      className: "user-icon",
    },
    {
      component: <HeartIcon color="white" />,
      condition: !!user?.phone,
      link: ProfileRoutesList.FAVORITES,
      hasNotification: showFavoritesNotification,
      className: "heart-icon"
    },
    {
      component: (
        <ContactsTabV2
          asButton={false}
          iconStyles="custom-icon-styles"
        />
      ),
      condition: true,
      className: "phone-icon",
    },
    {
      component: (
        <SearchIcon width={searchIcon.width} height={searchIcon.height} />
      ),
      condition: true,
      className: "search-icon"
    },
    {
      component: (
        <ShoppingBagIcon
          width={shoppingBagIcon.width}
          height={shoppingBagIcon.height}
        />
      ),
      condition: true,
      link: "/cart",
      notificationColor: cartNotificationColor,
      className: "shopping-bag-icon"
    }
  ];

  return (
    <nav className={styles.root}>
      <ul className={styles.list}>
        {icons.map(
          (icon, index) =>
            icon.condition && (
              <li
                key={index}
                className={cn(styles.item, styles[icon.className])}
              >
                {icon.link ? (
                  <Link to={icon.link} className={styles.item__link}>
                    {icon.component}
                    {icon.className === "shopping-bag-icon" &&
                      icon.notificationColor && (
                      <span
                        className={cn(styles.notification, {
                          [styles["notification--red"]]:
                              icon.notificationColor === "red",
                          [styles["notification--gray"]]:
                              icon.notificationColor === "gray"
                        })}
                      ></span>
                    )}
                    {icon.className !== "shopping-bag-icon" &&
                      icon.hasNotification && (
                      <span className={styles.notification}></span>
                    )}
                  </Link>
                ) : icon.className === "search-icon" ? (
                  <button id="global-search" className={styles.item__button}>
                    {icon.component}
                    {icon.hasNotification && (
                      <span className={styles.notification}></span>
                    )}
                  </button>
                ) : icon.className === "phone-icon" ? (
                  icon.component
                ) : (
                  <button
                    onClick={handleUserIconClick}
                    className={styles.item__button}
                  >
                    {icon.component}
                    {icon.hasNotification && (
                      <span className={styles.notification}></span>
                    )}
                  </button>
                )}
              </li>
            )
        )}
      </ul>
    </nav>
  );
};

export default UserBlockV2;
