import { IBrand } from "types/IBrand";
import { IBrandWrapped } from "types/ICatalog";
import { IMetaTotal } from "types/IMetaTotal";
import {
  CustomError,
  getErrorData,
  IErrorData,
  IGenericResponseRoot
} from "utils/getErrorData";

export interface IBrandResponse {
  items: IBrand[];
  meta: IMetaTotal;
}

export interface IBrandsByLettersResponse {
  parfumeria: { [key: string]: IBrandWrapped[] };
  kosmetika: { [key: string]: IBrandWrapped[] };
}

const getAllBrands = async (): Promise<
  IGenericResponseRoot<IBrandResponse> | IErrorData
> => {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/public/brands`,
      {
        method: "GET"
      }
    );

    if (res.status === 500) {
      throw new Error("Произошла необработанная ошибка при получении брендов");
    }

    const data: IGenericResponseRoot<IBrandResponse> = await res.json();

    if (!res.ok) {
      const errorData = getErrorData(data);
      throw new CustomError(errorData.message, errorData?.code);
    }

    if (data.response === null) {
      throw new CustomError("Не удалось получить бренды");
    }

    return data;
  } catch (error) {
    const errorData = getErrorData(error);
    return errorData;
  }
};

const getBrandsByLetters = async (
  letters: string[]
): Promise<IGenericResponseRoot<IBrandsByLettersResponse> | IErrorData> => {
  try {
    const lettersString = letters.join(",");
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/public/brands/v2/symbols?symbols=${lettersString}`,
      {
        method: "GET"
      }
    );

    if (res.status === 500) {
      throw new Error(
        "Произошла необработанная ошибка при получении брендов по буквам"
      );
    }

    const data: IGenericResponseRoot<IBrandsByLettersResponse> =
      await res.json();

    if (!res.ok) {
      const errorData = getErrorData(data);
      throw new CustomError(errorData.message, errorData?.code);
    }

    if (data.response === null) {
      throw new CustomError("Не удалось получить бренды по буквам");
    }

    return data;
  } catch (error) {
    const errorData = getErrorData(error);
    return errorData;
  }
};

export const brandsApi = {
  getAllBrands,
  getBrandsByLetters
};
