import { useMemo } from "react";
import { IGetUserOrderDataItem } from "types/IGetUserOrderData";
import { formatPrice } from "utils/helpers/formatedPrice";
import { capitalizeFirstLetter } from "utils/helpers/capitalizeFirstLetter";

import cn from "classnames";
import styles from "./styles.module.scss";

interface OrderTotalSumProps {
  order: IGetUserOrderDataItem;
}

interface Calculations {
  paymentProvider: string;
  totalItemsCostWithOutDiscount: number;
  totalDiscount: number;
  totalToPay: number;
  statusMessage: string;
}

interface InfoItem {
  label: string;
  value: string;
}

const OrderTotalSum: React.FC<OrderTotalSumProps> = ({ order }) => {

  const calculations: Calculations = useMemo(() => {
    const paymentProvider =
      order.payments && order.payments.length > 0
        ? order.payments[0].provider
        : "\u2012";

    const totalItemsCostWithOutDiscount = order.orderOffers.reduce((total, offer) => {
      return total + (offer.offer.oldPrice || 0) * offer.count;
    }, 0);

    const totalItemsCostWithDiscount = order.orderOffers.reduce((total, offer) => {
      return total + (offer.sale_price || 0) * offer.count;
    }, 0);

    const totalDiscount = totalItemsCostWithOutDiscount - totalItemsCostWithDiscount;

    const totalToPay = totalItemsCostWithDiscount + (order.delivery_amount || 0);

    const statusMessage = order.payments.some(
      (payment) => payment.status === "wait_for_commit"
    )
      ? "Ожидает подтверждения"
      : order.payments.length === 0 ||
          order.payments.every((payment) => payment.status !== "paid")
        ? "Не оплачен"
        : "Оплачен";

    return {
      paymentProvider,
      totalItemsCostWithOutDiscount,
      totalDiscount,
      totalToPay,
      statusMessage
    };
  }, [order.payments, order.orderOffers, order.delivery_amount]);

  const {
    paymentProvider,
    totalItemsCostWithOutDiscount,
    totalDiscount,
    totalToPay,
    statusMessage
  } = calculations;

  const infoItems: InfoItem[] = [
    { label: "Текущий статус оплаты", value: statusMessage },
    { label: "Способ оплаты", value: capitalizeFirstLetter(paymentProvider) },
    { label: "Стоимость товаров", value: `${formatPrice(totalItemsCostWithOutDiscount)} ₽` },
    { label: "Скидка", value: `${formatPrice(totalDiscount)} ₽` },
    { label: "Доставка", value: `${formatPrice(order.delivery_amount)} ₽` }
  ];

  return (
    <div className={styles.order}>
      <div className={styles.order__content}>
        <h2 className={styles.order__title}>Сумма покупки</h2>
        {infoItems.map((item, index) => (
          <div key={index} className={styles.item}>
            <span className={styles.item__label}>{item.label}</span>
            <span
              className={cn(styles.item__value, {
                [styles["item__value--success"]]:
                  item.label === "Текущий статус оплаты" &&
                  item.value === "Оплачен",
                [styles["item__value--fail"]]:
                  item.label === "Текущий статус оплаты" &&
                  item.value === "Не оплачен",
                [styles["item__value--pending"]]:
                  item.label === "Текущий статус оплаты" &&
                  item.value === "Ожидает подтверждения"
              })}
              data-testid={`order-${item.label}`}
            >
              {item.value}
            </span>
          </div>
        ))}
      </div>
      <div className={styles.wrapper}>
        <div className={styles.divider}></div>
        <div className={styles.total}>
          <span className={styles.total__label}>Всего к оплате</span>
          <span className={styles.total__value} data-testid="order-total">
            {formatPrice(totalToPay)} ₽
          </span>
        </div>
      </div>
    </div>
  );
};

export default OrderTotalSum;
