import { Link } from "react-router-dom";
import InfoPage from "../InfoPage";
import SectionTitle from "../../../components/SectionTitle/SectionTitle";
import pageInfo from "../pageInfo.json";

import styles from "./styles.module.scss";
import { getCanonicalUrlForInfoPage } from "utils/helpers/getCanonicalUrl";
import { formatPrice } from "utils/helpers/formatedPrice";

const SaleSystemPage: React.FC = () => {
  const saleSystemData = pageInfo.discountSystem;
  const canonicalUrl = getCanonicalUrlForInfoPage(window.location.href);

  return (
    <InfoPage metaData={saleSystemData} canonicalUrl={canonicalUrl}>
      <h1 className={styles.seo}>Инфо страница Система скидок</h1>
      <SectionTitle
        title="Система скидок"
        containerStyles={styles["custom-title"]}
      />
      <div className={styles.root}>
        <ol className={styles.list}>
          <li className={styles.list__item}>
            <h3 className={styles.list__item_title}>
              Накопительная система скидок
            </h3>
            <p>
              Для постоянных клиентов интернет-магазина{" "}
              <Link
                data-cke-saved-href="https://parfumart.ru/"
                to="/"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.item_link}
              >
                Parfumart.ru
              </Link>
              , мы предлагаем накопительную систему скидок. Чтобы стать
              участником программы, Вам необходимо зарегистрироваться на нашем
              сайте, после чего совершать заказы только через личный кабинет,
              чтобы велся автоматический учет выкупленных заказов. При
              совершении выкупленных заказов на сумму <span>{formatPrice(30000)}</span>{" "}
              (тридцать тысяч) рублей и более, Вы автоматически получаете скидку
              в <span>5%</span> на постоянной основе.
            </p>
            <p className={styles.list__item_postscript}>
              Данная скидка суммируется со всеми акционными предложениями на
              сайте, кроме товаров из категории «Распродажа». Все товары данной
              категории имеют соответствующую метку. Цена таких товаров является
              окончательной и изменению не подлежит.
            </p>
          </li>
          <li className={styles.list__item}>
            <h3 className={styles.list__item_title}>Разовая скидка</h3>
            <p>
              При совершении выкупленных заказов на сумму <span>{formatPrice(15000)}</span>{" "}
              (пятнадцать тысяч) рублей и более, Вы автоматически получаете
              скидку в <span>5%</span> на постоянной основе. Регистрация в
              данном случае не обязательна.
            </p>
          </li>
          <li className={styles.list__item}>
            <h3 className={styles.list__item_title}>Акции и Распродажа</h3>
            <p>
              Став подписчиком аккаунта <span>Parfumart.ru</span> в{" "}
              <a
                href="https://vk.com/parfumart"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.list__item_link}
              >
                VK
              </a>
              , Вы получаете доступ к закрытым акциям и распродажам, информация
              о которых есть только на данном ресурсе и не дублируется на сайте.
              Адрес аккаунта в <span>VK:</span>
              <a
                href="https://vk.com/parfumart"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.list__item_link}
              >
                vk.com/parfumart
              </a>
            </p>
          </li>
          <li className={styles.list__item}>
            <h3 className={styles.list__item_title}>Карты рассрочки</h3>
            <p>
              Все клиенты
              <Link
                data-cke-saved-href="https://parfumart.ru/"
                to="/"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.item_link}
              >
                {" "}
                Parfumart.ru
              </Link>{" "}
              имеют возможность оплачивать любые заказы картами рассрочки наших
              партнеров:
            </p>
            <ul className={styles["list__item__banks-list"]}>
              <li>Халва (СовКомБанк)</li>
              <li>Свобода (Хоум Кредит)</li>
            </ul>

            <p>
              Все торговые предложения из категории <strong>«Акция»</strong> при
              таком способе оплаты остаются действительными, но есть некоторые
              ограничения:
            </p>
            <ul className={styles["list__item__restrictions-list"]}>
              <li>
                а) при оплате заказа картой рассрочки скидка постоянного клиента
                не применяется не при каких обстоятельствах;
              </li>
              <li>
                б) Коммерческие предложения из категории{" "}
                <strong>«Распродажа»</strong> недействительны при рассрочке с
                блестящими картами.
              </li>
            </ul>
            <p>
              Такие ограничения связаны с тем, что наш магазин уже платит
              комиссию банку-эмитенту карты за предоставление услуги рассрочки.
              Поэтому надеемся на Ваше понимание.
            </p>
          </li>
        </ol>
      </div>
    </InfoPage>
  );
};

export default SaleSystemPage;
