import { useEffect, useRef, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "store/reduxHooks";
import useOutsideClick from "../../../utils/hooks/useOutsideClick";

import BrandsByLetterController from "../../../components/BrandsByLetter/BrandsByLetterController/BrandsByLetterController";
import BottomSheet from "../../../components/UI/Bottomsheet/Bottomsheet";
import BrandsMobile from "../../../components/BrandsMobile/BrandsMobile";
import CitySelect from "components/CitySelect/CitySelect";
import UserIcon from "assets/profile/icons/UserIcon";
import HeartIcon from "assets/profile/icons/HeartIcon";
import { ProfileRoutesList } from "utils/constants/routes";
import ContactsTabV2 from "pages/Main/components/ContactsTabV2/ContactsTabV2";

import cn from "classnames";
import styles from "./styles.module.scss";

interface NavigationTitle {
  id: number;
  title: string;
  link?: string;
}

// если фильтр в Каталоге относится к product_properties - мы переименовываем его ключ в properties[ключ]
const NAVIGATION_TITLES: NavigationTitle[] = [
  {
    id: 0,
    title: "Бренды"
  },
  {
    id: 1,
    title: "Парфюмерия",
    link: "/catalog/parfumeria"
  },
  {
    id: 2,
    title: "Лицо",
    // если фильтр в Каталоге относится к product_properties - мы переименовываем его ключ в properties[ключ]
    link: "/catalog/kosmetika?properties%5Bkategoria%5D=dla-lica"
  },
  {
    id: 3,
    title: "Тело",
    // если фильтр в Каталоге относится к product_properties - мы переименовываем его ключ в properties[ключ]
    link: "/catalog/kosmetika?properties%5Bkategoria%5D=dla-tela"
  },
  {
    id: 4,
    title: "Макияж",
    // если фильтр в Каталоге относится к product_properties - мы переименовываем его ключ в properties[ключ]
    link: "/catalog/kosmetika?properties%5Bkategoria%5D=dla-makiaza"
  },
  {
    id: 5,
    title: "Волосы",
    // если фильтр в Каталоге относится к product_properties - мы переименовываем его ключ в properties[ключ]
    link: "/catalog/kosmetika?properties%5Bkategoria%5D=dla-volos"
  },
  {
    id: 6,
    title: "Акции",
    link: "/catalog?badges=sale"
  }
];

interface CatalogTitlesProps {
  containerStyles?: string;
  onCloseMenu?: () => void;
  currentCity: string;
  setCurrentCity: (city: string) => Promise<void>;
  cityModalRef: React.MutableRefObject<HTMLDivElement | null>;
  selectCitiesModalRef: React.MutableRefObject<HTMLDivElement | null>;
  setIsOpenCityModal: (value: React.SetStateAction<boolean>) => void;
  setShowSelectCities: (value: React.SetStateAction<boolean>) => void;
  isOpenCityModal: boolean;
  showSelectCities: boolean;
  onCloseModals: () => void;
  menuRef: React.RefObject<HTMLDivElement>;
}

const CatalogTitlesV2: React.FC<CatalogTitlesProps> = (props) => {
  const location = useLocation();
  const [currentNavigationTitleId, setCurrentNavigationTitleId] = useState<
    number | null
  >(null);
  const [showBrandsSelect, setShowBrandsSelect] = useState(false);
  const [isOpenBottomSheet, setIsOpenBottomSheet] = useState(false);
  const { deviceType, user } = useAppSelector((state) => state.user);
  const isUserAuthorized = !!user?.phone;

  const brandsRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();

  useOutsideClick({
    ref: brandsRef,
    cb: () => setShowBrandsSelect(false)
  });

  useEffect(() => {
    const foolPath = `${location.pathname}${location.search}`;
    const currentNavigationTitle = NAVIGATION_TITLES.find((item) => {
      return item.link === foolPath;
    });

    if (currentNavigationTitle) {
      setCurrentNavigationTitleId(currentNavigationTitle.id);
      return;
    }
    setCurrentNavigationTitleId(null);
  }, [location]);

  const closeMenu = () => {
    if (props.onCloseMenu) {
      props.onCloseMenu();
    }
  };

  const onClickToggleNavigationTitle = (item: NavigationTitle) => {
    if (!item.link) {
      return;
    }
    setCurrentNavigationTitleId(item.id);
    closeMenu();
  };

  const toggleBrandsSelectStatus = (item: NavigationTitle) => {
    if (deviceType.isMobile || deviceType.isTablet) {
      setIsOpenBottomSheet(true);
      closeMenu();
      return;
    }
    onClickToggleNavigationTitle(item);
    setShowBrandsSelect(!showBrandsSelect);
  };

  const onCloseBrandsSelect = () => {
    setShowBrandsSelect(false);
  };

  const showButton = (item: NavigationTitle) => {
    if (!item.link) return true;
  };

  const onClickHandler = () => {
    if (!isUserAuthorized) {
      navigate("/sign-in", { replace: true });
    } else {
      navigate("/profile", { replace: true });
    }
  };

  const tableAndMobile = deviceType.isMobile || deviceType.isTablet;

  return (
    <>
      <nav className={cn(styles.root, props.containerStyles)}>
        <ul className={styles.list}>
          {NAVIGATION_TITLES.map((item) => {
            return (
              <li
                key={item.id}
                className={cn(styles.list__item, {
                  [styles["list__item--active"]]:
                    currentNavigationTitleId === item.id,
                  [styles["list__item--sale"]]: item.id === 6
                })}
              >
                {showButton(item) ? (
                  <button onClick={() => toggleBrandsSelectStatus(item)}>
                    {item.title}
                  </button>
                ) : (
                  <NavLink
                    to={item.link ?? "/"}
                    onClick={() => onClickToggleNavigationTitle(item)}
                  >
                    {item.title}
                  </NavLink>
                )}
              </li>
            );
          })}
          {tableAndMobile && (
            <>
              <div className={styles.divider}></div>
              <ContactsTabV2
                asButton={true}
                buttonStyles={styles["contacts-button"]}
                iconStyles={styles["icon-wrapper"]}
                withOverlay={true}
                customPositionStyles={true}
                menuRef={props.menuRef}
              />
              {isUserAuthorized && (
                <li className={styles["list__item__heart"]}>
                  <NavLink
                    to={ProfileRoutesList.FAVORITES}
                    className={styles["heart-link"]}
                    onClick={closeMenu}
                  >
                    <HeartIcon color="white" strokeColor="#A5006D" />
                    <span>Избранное</span>
                  </NavLink>
                </li>
              )}
              <li className={styles["list__item__city-select"]}>
                <CitySelect
                  currentCity={props.currentCity}
                  setCurrentCity={props.setCurrentCity}
                  cityModalRef={props.cityModalRef}
                  selectCitiesModalRef={props.selectCitiesModalRef}
                  setIsOpenCityModal={props.setIsOpenCityModal}
                  setShowSelectCities={props.setShowSelectCities}
                  isOpenCityModal={props.isOpenCityModal}
                  showSelectCities={props.showSelectCities}
                  onCloseModals={props.onCloseModals}
                />
              </li>
              <li className={styles["list__item__profile"]}>
                <button
                  onClick={onClickHandler}
                  className={styles["profile-button"]}
                >
                  <UserIcon color="#A5006D" />
                  <span>{user?.phone ? "Личный кабинет" : "Вход / Регистрация"}</span>
                </button>
              </li>
            </>
          )}
        </ul>
        {showBrandsSelect && (
          <BrandsByLetterController
            modalRef={brandsRef}
            onClose={onCloseBrandsSelect}
          />
        )}
      </nav>
      <BottomSheet
        isOpen={isOpenBottomSheet}
        onClose={() => setIsOpenBottomSheet(false)}
      >
        <BrandsMobile onClose={() => setIsOpenBottomSheet(false)} />
      </BottomSheet>
    </>
  );
};

export default CatalogTitlesV2;
