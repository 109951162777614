interface WhatsAppIconProps {
  width?: number;
  height?: number;
  color?: string;
}

const WhatsAppIcon: React.FC<WhatsAppIconProps> = ({
  width = 26,
  height = 26,
  color = "white",
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.5577 15.4212C18.249 15.2749 16.7702 14.5437 16.494 14.4299C16.2177 14.3162 16.0227 14.2837 15.8115 14.5924C15.6002 14.9012 15.0315 15.5674 14.8527 15.7787C14.674 15.9899 14.5115 16.0062 14.2027 15.7787C13.3159 15.4228 12.4969 14.9171 11.7815 14.2837C11.1338 13.6739 10.5856 12.9666 10.1565 12.1874C9.97773 11.8949 10.1565 11.7324 10.2865 11.5699C10.4165 11.4074 10.579 11.2287 10.7415 11.0499C10.8603 10.8953 10.9586 10.726 11.034 10.5462C11.0743 10.4625 11.0952 10.3709 11.0952 10.2781C11.0952 10.1852 11.0743 10.0936 11.034 10.0099C11.034 9.86368 10.3515 8.38493 10.0915 7.78368C9.83148 7.18243 9.60398 7.26368 9.42523 7.26368H8.77523C8.46559 7.27575 8.17339 7.41016 7.96273 7.63743C7.62272 7.96189 7.35339 8.3531 7.17163 8.78652C6.98987 9.21994 6.8996 9.68624 6.90648 10.1562C6.9896 11.3099 7.41382 12.4129 8.12523 13.3249C9.43071 15.2643 11.2167 16.8319 13.309 17.8749C14.024 18.1837 14.5765 18.3624 15.0152 18.5087C15.6313 18.6949 16.2826 18.7339 16.9165 18.6224C17.3375 18.537 17.7365 18.366 18.0887 18.12C18.4409 17.874 18.7388 17.5583 18.964 17.1924C19.1514 16.7406 19.2131 16.2465 19.1427 15.7624C19.0615 15.6487 18.8665 15.5674 18.5577 15.4212Z"
      fill={color}
    />
    <path
      d="M21.5965 4.35493C20.4727 3.22049 19.1331 2.32258 17.6567 1.71416C16.1803 1.10574 14.597 0.799137 13.0002 0.812426C10.885 0.823495 8.80961 1.38935 6.98147 2.45348C5.15332 3.51761 3.63636 5.04277 2.5821 6.87663C1.52783 8.71048 0.97317 10.7889 0.973502 12.9042C0.973833 15.0195 1.52915 17.0976 2.58398 18.9312L0.958984 25.1874L7.36149 23.5624C9.13149 24.5256 11.1152 25.0285 13.1302 25.0249H13.0002C15.4019 25.0406 17.7537 24.3406 19.7559 23.0142C21.7581 21.6879 23.3199 19.7952 24.2421 17.5777C25.1644 15.3602 25.4052 12.9182 24.934 10.5632C24.4629 8.20821 23.3009 6.04691 21.5965 4.35493ZM13.0002 22.9449C11.1974 22.9464 9.42805 22.4577 7.88148 21.5312L7.52398 21.3199L3.72148 22.3112L4.72898 18.6062L4.50148 18.2324C3.20369 16.1424 2.71823 13.6487 3.13721 11.2245C3.5562 8.80024 4.85048 6.61414 6.77448 5.08095C8.69848 3.54776 11.1183 2.77416 13.5749 2.90692C16.0315 3.03968 18.3539 4.06957 20.1015 5.80118C21.0384 6.7305 21.7809 7.83699 22.2859 9.05616C22.7909 10.2753 23.0481 11.5828 23.0427 12.9024C23.0384 15.5645 21.979 18.1164 20.0966 19.9988C18.2142 21.8812 15.6624 22.9406 13.0002 22.9449Z"
      fill={color}
    />
  </svg>
);

export default WhatsAppIcon;