import { useEffect, useRef, useState } from "react";
import useOutsideClick from "utils/hooks/useOutsideClick";
import { CSSTransition } from "react-transition-group";
import PhoneIcon from "assets/profile/icons/PhoneIcon";
import ContactsInnerContent from "../../../../components/ContactsInnerContent/ContactsInnerContent";
import { useAppDispatch, useAppSelector } from "store/reduxHooks";
import cn from "classnames";
import styles from "./styles.module.scss";
import { setOverlayActive } from "store/user/user";

interface ContactsTabV2Props {
  asButton?: boolean;
  buttonText?: string;
  buttonStyles?: string;
  iconStyles?: string;
  cb?: () => void;
  withOverlay?: boolean;
  customPositionStyles?: boolean;
  menuRef?: React.RefObject<HTMLDivElement>;
}

const ContactsTabV2: React.FC<ContactsTabV2Props> = ({
  asButton = false,
  buttonText = "Контакты",
  buttonStyles,
  iconStyles,
  withOverlay = false,
  customPositionStyles = false,
  menuRef,
}) => {
  const { user } = useAppSelector((state) => state.user);
  const [isOpen, setIsOpen] = useState(false);
  const blockRef = useRef<HTMLLIElement | null>(null);
  const modalRef = useRef<HTMLDivElement | null>(null);
  const isUserAuthorized = !!user?.phone;

  const dispatch = useAppDispatch();

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (isOpen) {
      const matches = window.matchMedia(
        "(max-width: 500px) and (min-height: 600px) and (max-height: 780px)"
      ).matches;

      if (matches && modalRef.current && menuRef?.current) {
        const containerScrollTop = menuRef.current.scrollTop;
        const displaySize = window.innerHeight;

        const calculatedTop = `${(displaySize / 2) + containerScrollTop - 10}px`;
        modalRef.current.style.top = calculatedTop;
      }
    }
  }, [isOpen, menuRef]);

  useOutsideClick({
    ref: blockRef,
    cb: () => setIsOpen(false)
  });

  useEffect(() => {
    const handleScroll = () => {
      setIsOpen(false);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    dispatch(setOverlayActive(withOverlay && isOpen));
  }, [withOverlay, isOpen, dispatch]);

  return (
    <>
      {withOverlay && isOpen && (
        <div className={styles.overlay} onClick={() => setIsOpen(false)}></div>
      )}
      <li ref={blockRef} className={styles.root}>
        {asButton ? (
          <button
            onClick={handleClick}
            className={cn(styles["contact-button"], buttonStyles)}
          >
            <PhoneIcon color="#A5006D" />
            <span>{buttonText}</span>
          </button>
        ) : (
          <div
            onClick={handleClick}
            className={cn(styles["icon-wrapper"], iconStyles)}
          >
            <PhoneIcon />
          </div>
        )}
        <CSSTransition
          in={isOpen}
          timeout={200}
          classNames={{
            enter: styles["contacts-enter"],
            enterActive: styles["contacts-enter-active"],
            exit: styles["contacts-exit"],
            exitActive: styles["contacts-exit-active"]
          }}
          unmountOnExit
        >
          <ContactsInnerContent
            containerStyles={cn(styles.contacts, {
              [styles["contacts--custom"]]: customPositionStyles,
              [styles["authorized"]]: isUserAuthorized
            })}
            onClose={() => setIsOpen(false)}
            ref={modalRef}
          />
        </CSSTransition>
      </li>
    </>
  );
};

export default ContactsTabV2;
