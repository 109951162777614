import { nanoid } from "@reduxjs/toolkit";
import popularSectionLogos from "./constants/popularSectionLogos";
import { IPopularItem } from "types/IPopularItem";
// если фильтр в Каталоге относится к product_properties - мы переименовываем его ключ в properties[ключ] пример properties%5Bkategoria%5D
const getPopularBlockItems = (isMobile: boolean, isTablet: boolean): IPopularItem[] => {
  const isMobileOrTablet = isMobile || isTablet;
  return [
    {
      id: nanoid(),
      title: "Селективная парфюмерия",
      src: popularSectionLogos.selectiveIcon,
      link: "/catalog/parfumeria?properties%5Bkategoria%5D=selektivnaa",
      alt: "selective-icon",
      width: isMobileOrTablet ? "80px" : "124px",
      height: isMobileOrTablet ? "auto" : "146px"
    },
    {
      id: nanoid(),
      title: "Для волос",
      src: popularSectionLogos.forChildsIcon,
      link: "/catalog/kosmetika?properties%5Bkategoria%5D=dla-volos",
      alt: "hair-icon",
      width: isMobileOrTablet ? "28px" : "54px",
      height: isMobileOrTablet ? "92px" : "157px"
    },
    {
      id: nanoid(),
      title: "Для лица и тела",
      src: popularSectionLogos.licoAndBodyIcon,
      link: "/catalog/kosmetika?properties%5Bkategoria%5D=dla-lica%2Cdla-tela",
      alt: "for_body-and-face-icon",
      width: isMobileOrTablet ? "107px" : "194px",
      height: isMobileOrTablet ? "67px" : "114px",
    },
    {
      id: nanoid(),
      title: "Мужская косметика",
      src: popularSectionLogos.forMensIcon,
      link: "/catalog/kosmetika?properties%5Bklassifikacia%5D=muzskaa-kosmetika",
      alt: "for_mens-icon",
      width: isMobileOrTablet ? "53px" : "92px",
      height: isMobileOrTablet ? "auto" : "159px"
    },
  ];
};

export default getPopularBlockItems;
