import { useState } from "react";
import CustomProfileInput from "components/UI/CustomProfileInput/CustomProfileInput";
import CustomButtonV2 from "components/UI/CustomButtonV2/CustomButtonV2";
import CheckboxCustom from "components/UI/CheckboxV2/CheckboxV2";
import { toast } from "react-toastify";
import { emailRegex } from "utils/helpers/regularEmail";

import styles from "./styles.module.scss";

const SubscriptionBlockV2: React.FC = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");


  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\s/g, "");
    setEmail(value);
    setEmailError("");
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };

  const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!email) {
      return toast("Пожалуйста, введите ваш email для подписки.");
    }
    if (!emailRegex.test(email)) {
      setEmailError("Введите корректный email.");
      return;
    }
    if (!isChecked) {
      return toast("Необходимо дать согласие на обработку данных.");
    }
    setEmail("");
    setIsChecked(false);
    toast("Вы успешно подписались на акции и распродажи!");
  };
  return (
    <form className={styles.subscription} onSubmit={handleSubmit} noValidate>
      <h3 className={styles.subscription__title}>
        Подпишитесь на наши акции и распродажи
      </h3>
      <div className={styles.subscription__row}>
        <div className={styles.subscription__wrapper}>
          <CustomProfileInput
            placeholder="Введите свой email"
            inputType="email"
            value={email}
            onChange={handleEmailChange}
            onKeyDown={handleKeyDown}
            customInputStyles={styles.subscription__input}
            isError={!!emailError}
            helpText={emailError}
          />
          <CustomButtonV2
            title="Подписаться"
            size="small"
            color="secondary"
            type="submit"
          />
        </div>
        <div>
          <CheckboxCustom
            checked={isChecked}
            onCustomChange={setIsChecked}
            label="Я согласен на обработку данных в соответствии с ФЗ РФ от 27.07.2006, №152 ФЗ “О персональных данных”"
            labelClassName={styles["custom-label"]}
            variant={isChecked ? "check" : "default"}
          />
        </div>
      </div>
    </form>
  );
};

export default SubscriptionBlockV2;
