interface TelegramIconProps {
  width?: number;
  height?: number;
  color?: string;
}

const TelegramIcon: React.FC<TelegramIconProps> = ({ width = 27, height = 22, color = "white" }) => (
  <svg width={width} height={height} viewBox="0 0 27 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.28736 9.47086C9.26667 6.36615 13.9206 4.31934 16.2493 3.33042C22.8979 0.506852 24.2795 0.0163687 25.18 0.000172686C25.378 -0.00338946 25.8208 0.0467251 26.1077 0.284372C26.3499 0.485037 26.4165 0.756106 26.4484 0.946358C26.4803 1.13661 26.52 1.57001 26.4884 1.90866C26.1281 5.7739 24.5692 15.1538 23.776 19.483C23.4404 21.3148 22.7796 21.929 22.1399 21.9891C20.7496 22.1197 19.6938 21.051 18.3473 20.1497C16.2401 18.7394 15.0498 17.8615 13.0044 16.4853C10.6407 14.8949 12.173 14.0208 13.5201 12.5922C13.8726 12.2184 19.9983 6.52943 20.1169 6.01335C20.1317 5.94881 20.1455 5.70822 20.0055 5.58118C19.8655 5.45414 19.6589 5.49758 19.5098 5.53213C19.2985 5.5811 15.9323 7.85279 9.41132 12.3472C8.45585 13.0171 7.59041 13.3435 6.81501 13.3264C5.96019 13.3075 4.31586 12.8329 3.09347 12.4272C1.59416 11.9296 0.402535 11.6665 0.506299 10.8214C0.560345 10.3812 1.15403 9.93102 2.28736 9.47086Z"
      fill={color}
    />
  </svg>
);

export default TelegramIcon;