import ProductCardV2 from "components/ProductCardV2/ProductCardV2";
import { useAppDispatch, useAppSelector } from "store/reduxHooks";
import SkeletonBestsellerCard from "components/UI/Skeleton/SkeletonBestsellerCard/SkeletonBestsellerCard";
import HeartIcon from "assets/profile/icons/HeartIcon";
import CustomLinkV2 from "components/UI/CustomLinkV2/CustomLinkV2";
import { useCallback, useEffect, useRef, useState } from "react";
import { getUserFavoriteProducts } from "store/user/userThunks/userThunks";
import { IProductWithOffers } from "types/IProduct";
import { getErrorData } from "utils/getErrorData";
import ProfileWrapper from "../ProfileWrapper/ProfileWrapper";
import styles from "./styles.module.scss";

const LIMIT = 20;

const Favorites: React.FC = () => {
  const { user, isFavoriteLoading, deviceType } = useAppSelector((state) => state.user);
  const countFavoriteProducts = user?.favouriteProducts.length || 0;
  const catalogLink = "/catalog";
  const dispatch = useAppDispatch();
  const favouriteProductsRef = useRef<IProductWithOffers[]>([]);
  const [, setUpdate] = useState(false);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const observer = useRef<IntersectionObserver | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const skeletonCount = deviceType.isMobile ? 10 : 18;

  const skeletonCounts =
  countFavoriteProducts > skeletonCount
    ? 20
    : countFavoriteProducts || skeletonCount;

  const loadFavoriteProducts = useCallback(async () => {
    if (!hasMore || isFavoriteLoading || isLoading) return;

    setIsLoading(true);

    try {
      const response = await dispatch(
        getUserFavoriteProducts({ limit: LIMIT, offset })
      ).unwrap();

      const { items: favouriteProducts, meta } = response;

      if (favouriteProducts.length > 0) {

        const uniqueProducts = favouriteProducts.filter(
          (newItem) =>
            !favouriteProductsRef.current.some(
              (existingItem) => existingItem.id === newItem.product.id
            )
        );

        favouriteProductsRef.current = [
          ...favouriteProductsRef.current,
          ...uniqueProducts.map((item) => {
            if ("offers" in item.product) {
              return item.product as IProductWithOffers;
            } else {
              return { ...item.product, offers: [] } as IProductWithOffers;
            }
          }),
        ];
        setOffset((prevOffset) => prevOffset + LIMIT);
        if (favouriteProductsRef.current.length >= meta.total) {
          setHasMore(false);
        }
        setUpdate((prev) => !prev);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      const errorData = getErrorData(error);
      // eslint-disable-next-line no-console
      console.error(
        "Ошибка при получении избранных продуктов пользователя:",
        errorData.message
      );
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, offset, hasMore, isFavoriteLoading, isLoading]);

  useEffect(() => {
    if (favouriteProductsRef.current.length === 0 && !isLoading) {
      loadFavoriteProducts();
    }
  }, [loadFavoriteProducts, isLoading]);

  // Не удалять подумать как можно оптимизировать

  // useEffect(() => {
  //   const hasProductsWithoutOffers = user.favouriteProducts.some(
  //     (item) => item.product.offers.length === 0
  //   );

  //   if (hasProductsWithoutOffers && !isLoading) {
  //     loadFavoriteProducts();
  //   } else {
  //     favouriteProductsRef.current = user.favouriteProducts
  //       .slice(-offset - LIMIT)
  //       .map((item) => item.product);

  //     setUpdate((prev) => !prev);
  //   }
  // }, [loadFavoriteProducts, isLoading, user.favouriteProducts, offset]);

  // useEffect(() => {
  //   const productsWithoutOffers = user.favouriteProducts.filter(
  //     (item) => item.product.offers.length === 0
  //   );

  //   if (productsWithoutOffers.length > 0 && !isLoading) {
  //     loadFavoriteProducts();
  //   } else {
  //     favouriteProductsRef.current = user.favouriteProducts.map((item) => item.product);
  //     setUpdate((prev) => !prev);
  //   }
  // }, [loadFavoriteProducts, isLoading, user.favouriteProducts]);

  const lastProductElementRef = useCallback(
    (node: HTMLElement | null) => {
      if (isFavoriteLoading || isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          loadFavoriteProducts();
        }
      });
      if (node) observer.current.observe(node);
    },
    [isFavoriteLoading, loadFavoriteProducts, hasMore, isLoading]
  );

  return (
    <ProfileWrapper title="Избранное">
      <div className={styles.table}>
        {favouriteProductsRef.current.length > 0 ? (
          <>
            {favouriteProductsRef.current.map((product, index) => (
              <div
                key={product.id}
                className={styles.table__cell}
                ref={
                  index === favouriteProductsRef.current.length - 1
                    ? lastProductElementRef
                    : undefined
                }
              >
                <ProductCardV2
                  product={product}
                  initialFavoriteStatus={true}
                />
              </div>
            ))}

            {(isFavoriteLoading || isLoading) &&
                Array.from({ length: skeletonCounts }).map((_, index) => (
                  <div key={`skeleton-${index}`} className={styles.table__cell}>
                    <SkeletonBestsellerCard isProfile />
                  </div>
                ))}
          </>
        ) : isFavoriteLoading || isLoading || countFavoriteProducts > 0 ? (
          Array.from({ length: skeletonCounts }).map((_, index) => (
            <div key={`skeleton-${index}`} className={styles.table__cell}>
              <SkeletonBestsellerCard isProfile />
            </div>
          ))
        ) : (
          <div className={styles.empty}>
            <p className={styles.empty__text}>
                  Нажмите{" "}
              <span className={styles["empty__icon-wrapper"]}>
                <HeartIcon color="gray" height={18} width={20} />
              </span>
                  , чтобы добавить товары в избранное
            </p>
            <CustomLinkV2
              to={catalogLink}
              title="Перейти в каталог"
              size="large"
              color="secondary"
              iconRight="Arrow"
              iconRightProps={{
                direction: "right",
                width: 15,
                height: 15,
              }}
            />
          </div>
        )}
      </div>
    </ProfileWrapper>
  );
};

export default Favorites;
