interface CrossIconProps {
  width?: number;
  height?: number;
  color?: string;
}

const CrossIcon: React.FC<CrossIconProps> = ({
  width = 20,
  height = 20,
  color = "#D0D5DD"
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.928627 0.928977C1.41678 0.440822 2.20824 0.440822 2.69639 0.928977L15.0708 13.3033C15.5589 13.7915 15.5589 14.583 15.0708 15.0711C14.5826 15.5593 13.7911 15.5593 13.303 15.0711L0.928626 2.69674C0.440471 2.20859 0.440471 1.41713 0.928627 0.928977Z"
      fill={color}
    />
    <path
      d="M0.928626 15.0711C0.44047 14.583 0.440471 13.7915 0.928626 13.3033L13.303 0.928978C13.7912 0.440822 14.5826 0.440822 15.0708 0.928978C15.5589 1.41713 15.5589 2.20859 15.0708 2.69674L2.69639 15.0711C2.20824 15.5593 1.41678 15.5593 0.928626 15.0711Z"
      fill={color}
    />
  </svg>
);

export default CrossIcon;
