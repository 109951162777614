interface MenuIconProps {
  width?: number;
  height?: number;
  color?: string;
  direction: "left" | "right";
}

const MenuIcon: React.FC<MenuIconProps> = ({
  width = 20,
  height = 14,
  color = "#191B38",
  direction
}) => {
  const leftIcon = (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 7H19M1 1H19M1 13H13"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const rightIcon = (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 7H1M19 1H1M19 13H7"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  return direction === "left" ? leftIcon : rightIcon;
};

export default MenuIcon;
