import { IGetUserOrderDataItem } from "types/IGetUserOrderData";
import BuildingIcon from "assets/profile/icons/BuildingIcon";
import RouteIcon from "assets/profile/icons/RouteIcon";
import CalendarIcon from "assets/profile/icons/CalendarIcon";
import PhoneIcon from "assets/profile/icons/PhoneIcon";
import UserIcon from "assets/profile/icons/UserIcon";
import MarkerIcon from "assets/profile/icons/MarkerIcon";
import { capitalizeFirstLetter } from "utils/helpers/capitalizeFirstLetter";
import styles from "./styles.module.scss";
import { formatPhoneNumber } from "utils/helpers/formatProfileData";

interface OrderInfoProps {
  order: IGetUserOrderDataItem;
}

interface InfoItem {
  icon: JSX.Element;
  label: string;
  value: string;
  testId: string;
}

const OrderInfo: React.FC<OrderInfoProps> = ({ order }) => {
  const deliveryProvider = order.delivery_provider
    ? capitalizeFirstLetter(order.delivery_provider)
    : "\u2012";

  const infoItems: InfoItem[] = [
    {
      icon: <BuildingIcon />,
      label: "Способ получения",
      value: deliveryProvider,
      testId: "delivery-provider"
    },
    {
      icon: <MarkerIcon />,
      label: "Адрес",
      value: order.orderRecipient.address || "\u2012",
      testId: "address"
    },
    {
      icon: <UserIcon />,
      label: "Получатель",
      value: order.orderRecipient.name || "\u2012",
      testId: "recipient-name"
    },
    {
      icon: <PhoneIcon />,
      label: "Телефон",
      value: formatPhoneNumber(order.orderRecipient.phone),
      testId: "phone"
    },
    {
      icon: <RouteIcon />,
      label: "Номер для отслеживания",
      value: order.delivery_track_number || "\u2012",
      testId: "tracking-number"
    },
    {
      icon: <CalendarIcon />,
      label: "Дата получения",
      value: "\u2012",
      testId: "delivery-date"
    }
  ];

  return (
    <div className={styles.order}>
      <h2 className={styles.order__title}>Информация о заказе</h2>
      {infoItems.map((item, index) => (
        <div key={index} className={styles.block}>
          <div className={styles.block__icon}>{item.icon}</div>
          <div className={styles.block__text}>
            <div className={styles.block__label}>{item.label}</div>
            <div className={styles.block__value} data-testid={item.testId}>{item.value}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default OrderInfo;
