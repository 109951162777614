import forChildsIcon from "../../assets/icons/popular_block-icons/popular4.png";
import forMensIcon from "../../assets/icons/popular_block-icons/popular3.png";
import makeupIcon from "../../assets/icons/popular_block-icons/popular1.png";
import selectiveIcon from "../../assets/icons/popular_block-icons/popular2.png";
import licoAndBodyIcon from "../../assets/icons/popular_block-icons/popular5.png";

export default {
  forChildsIcon,
  forMensIcon,
  makeupIcon,
  selectiveIcon,
  licoAndBodyIcon
};