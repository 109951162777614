import { useEffect, useState } from "react";

interface IconSizes {
  searchIcon: { width: number; height: number };
  shoppingBagIcon: { width: number; height: number };
}

export const useResponsiveIconSizes = (): IconSizes => {
  const [sizes, setSizes] = useState<IconSizes>({
    searchIcon: { width: 21, height: 21 },
    shoppingBagIcon: { width: 21, height: 23 },
  });

  useEffect(() => {
    const updateSizes = () => {
      const width = window.innerWidth;

      if (width >= 501) {
        setSizes({
          searchIcon: { width: 18, height: 18 },
          shoppingBagIcon: { width: 18, height: 20 },
        });
      } else if (width >= 360) {
        setSizes({
          searchIcon: { width: 24, height: 24 },
          shoppingBagIcon: { width: 24, height: 26 },
        });
      } else {
        setSizes({
          searchIcon: { width: 21, height: 21 },
          shoppingBagIcon: { width: 21, height: 23 },
        });
      }
    };
    updateSizes();

    window.addEventListener("resize", updateSizes);

    return () => {
      window.removeEventListener("resize", updateSizes);
    };
  }, []);

  return sizes;
};