import {
  COMPANY_LINKS,
  COSMETIC_LINKS,
  INFORMATION_LINKS,
  PARFUME_LINKS
} from "utils/constants/footerLinks";
import { Link } from "react-router-dom";

import cn from "classnames";
import styles from "./styles.module.scss";

const ListWithLinksV2: React.FC = () => {
  const columns = [
    { title: "Парфюмерия", links: PARFUME_LINKS, hideOnMobile: true },
    { title: "Косметика", links: COSMETIC_LINKS, hideOnMobile: true },
    { title: "Компания", links: COMPANY_LINKS, hideOnMobile: false },
    { title: "Информация", links: INFORMATION_LINKS, hideOnMobile: false }
  ];

  return (
    <section className={styles.list}>
      <div className={styles.list__container}>
        {columns.map((column, index) => (
          <div
            key={index}
            className={cn(styles.list__column, {
              [styles["list__column--hidden"]]: column.hideOnMobile
            })}
          >
            <h3 className={styles.list__title}>{column.title}</h3>
            <ul className={styles.list__links}>
              {column.links.map((link, idx) => (
                <li key={idx} className={styles.list__item}>
                  <Link to={link.link} className={styles.list__link}>
                    {link.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ListWithLinksV2;
