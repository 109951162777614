interface VKIconProps {
  width?: number;
  height?: number;
  color?: string;
}

const VKIcon: React.FC<VKIconProps> = ({ width = 27, height = 16, color = "white" }) => (
  <svg width={width} height={height} viewBox="0 0 27 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.6611 16C5.77787 16 0.711118 9.99399 0.5 0H4.94972C5.09588 7.33533 8.37625 10.4424 10.9746 11.0831V0H15.1647V6.32633C17.7306 6.05405 20.4261 3.17117 21.3355 0H25.5254C24.8271 3.90791 21.904 6.79079 19.8253 7.97598C21.904 8.93694 25.2333 11.4515 26.5 16H21.8877C20.8971 12.957 18.4289 10.6026 15.1647 10.2823V16H14.6611Z"
      fill={color}
    />
  </svg>
);

export default VKIcon;