import { useState, useEffect, useLayoutEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import { userApi } from "../api/userApi";
import { citiesApi } from "../api/citiesApi";
import {
  removeUser,
  resetCurrentPage,
  setDeviceType,
  setUser,
  setUserCurrentCity,
  viewFavorites
} from "../store/user/user";
import { getUserCart } from "../store/user/cartThunks/cartThunks";

import {
  clearValueFromStorage,
  getUserCityStorage,
  getUserToken,
  getValueFromStorage
} from "../utils/localStorage";
import { catalogPaths, personalAreaPaths, ProfileRoutesList, routesProfile } from "../utils/constants/routes";
import { createYMapsScript } from "../utils/ymapsUtils";
import getDeviceType from "../utils/getDeviceType";

import ProtectedRoute from "../components/ProtectedRoute/ProtectedRoute";
import LoadingItem from "../components/LoadingItem/LoadingItem";
// import Footer from "../layouts/Footer/Footer";
import {
  AboutUsPage,
  ContactsPage,
  Catalog,
  ForCompaniesPage,
  HowBuyPage,
  MainPage,
  PaymentPage,
  PresentCertificatePage,
  SaleSystemPage,
  ReturnAndExchange,
  WorkSchedulePage,
  SingleProductPage,
  SignUp,
  SignIn,
  NoIndexPage,
  UserCart,
  PublicOfferingPage,
  DeliveryPage,
  NotFoundPage,
  PaymentStatusPage,
  LandingPage,
} from "../pages";
import RestorePassword from "../pages/SignIn/components/RestorePassword/RestorePassword";
import RestoreByPhone from "../pages/SignIn/components/RestoreByPhone/RestoreByPhone";
import RestoreByEmail from "../pages/SignIn/components/RestoreByEmail/RestoreByEmail";
import RestorePasswordByAction from "../pages/SignIn/components/RestoreByPhone/RestorePasswordByAction/RestorePasswordByAction";
import Checkout from "../pages/UserCart/Checkout/Checkout";
import { DEFAULT_CITY } from "../utils/constants";
import { CustomError, getErrorData } from "utils/getErrorData";
import { useAppDispatch, useAppSelector } from "store/reduxHooks";
import { fetchBanners } from "store/banners/bannerThunks/bannerThunks";
import { isError } from "store/user/userThunks/userThunks";
import landingPagesConfig from "utils/config/landingPagesConfig";
import FooterV2 from "layouts/FooterV2/FooterV2";
import StickyHeaderV2 from "layouts/StickyHeaderV2/StickyHeaderV2";

const App: React.FC = () => {
  const { banners, deviceType, user } = useAppSelector((state) => ({
    banners: state.banners.banners,
    user: state.user.user,
    deviceType: state.user.deviceType
  }));
  const [isInit, setIsInit] = useState(false);
  const folder = "main_page";
  const isLoggedOut = getValueFromStorage("isLoggedOut") === "true";
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const router = useLocation();

  useLayoutEffect(() => {
    window.prerenderReady = false;
  }, []);

  useLayoutEffect(() => {
    if (personalAreaPaths.includes(router.pathname) && !deviceType.isMobile) {
      return;
    }
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.pathname]);

  useEffect(() => {
    const token = getUserToken();
    dispatch(getUserCart({}));

    if (!token || user.phone) {
      return setIsInit(true);
    }

    (async () => {
      try {
        const userData = await userApi.checkUser();

        if (isError(userData)) {
          throw new CustomError(userData.message, userData?.code);
        }

        if (userData.response !== null) {
          dispatch(setUser(userData.response));
        } else {
          throw new CustomError("Ошибка получения данных о пользователе", 500);
        }
      } catch (error) {
        const errorData = getErrorData(error);
        // eslint-disable-next-line no-console
        console.error(
          "Ошибка при получение данных о пользователе: ",
          errorData.message
        );
      } finally {
        setIsInit(true);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (getUserCityStorage && getUserCityStorage !== "") {
      dispatch(setUserCurrentCity(getUserCityStorage));
    } else {
      (async () => {
        try {
          const data = await citiesApi.getUserCity();
          if ("message" in data) {
            throw new Error(data.message);
          }

          if (!data.response) {
            throw new Error("Данных о населённом пункте пользователя нет");
          }

          dispatch(setUserCurrentCity(data.response.name));
        } catch (error) {
          const errorData = getErrorData(error);
          dispatch(setUserCurrentCity(DEFAULT_CITY));
          // eslint-disable-next-line no-console
          console.error("Произошла ошибка", errorData.message);
        }
      })();
    }
    if (isLoggedOut) {
      const logout = () => {
        dispatch(removeUser());
        clearValueFromStorage("user_city");
        navigate("/", { replace: true });
      };

      logout();
      localStorage.removeItem("isLoggedOut");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isLoggedOut]);

  useEffect(() => {
    createYMapsScript();
    dispatch(setDeviceType(getDeviceType()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let timerId: NodeJS.Timeout;
    const handleResize = () => {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        dispatch(setDeviceType(getDeviceType()));
      }, 100);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const onPageLoad = () => {
      const script = document.createElement("script");
      script.innerHTML = "window.prerenderReady = true;";
      document.body.appendChild(script);
    };

    if (document.readyState === "complete") {
      onPageLoad();
    } else {
      window.addEventListener("load", onPageLoad, false);

      return () => window.removeEventListener("load", onPageLoad);
    }
  }, []);

  useEffect(() => {
    if (!banners || !banners.length) {
      dispatch(fetchBanners(folder));
    }
  }, [dispatch, banners]);

  useEffect(() => {
    const path = router.pathname;
    const isMyOrdersPage = /^\/profile\/my-orders(\/\d+)?$/.test(path);

    if (!isMyOrdersPage) {
      dispatch(resetCurrentPage());
    }
  }, [router, dispatch]);

  useEffect(() => {
    if (router.pathname === ProfileRoutesList.FAVORITES) {
      dispatch(viewFavorites());
    }
  }, [router, dispatch]);

  if (!isInit) {
    return <LoadingItem />;
  }

  return (
    <>
      <StickyHeaderV2 />
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="product/:productId" element={<SingleProductPage />} />
        {catalogPaths.map((path, idx) => {
          return <Route key={idx} path={path} element={<Catalog />} />;
        })}
        <Route path="sign-in">
          <Route
            index
            element={
              <ProtectedRoute>
                <SignIn />
              </ProtectedRoute>
            }
          />
          <Route path="restore">
            <Route
              index
              element={
                <ProtectedRoute>
                  <RestorePassword />
                </ProtectedRoute>
              }
            />
            <Route path="by-phone">
              <Route
                index
                element={
                  <ProtectedRoute>
                    <RestoreByPhone />
                  </ProtectedRoute>
                }
              />
              <Route
                path="call-me"
                element={
                  <ProtectedRoute>
                    <RestorePasswordByAction restoreType="callMe" />
                  </ProtectedRoute>
                }
              />
              {/* <Route
                path='call'
                element={
                  <ProtectedRoute>
                    <RestorePasswordByAction restoreType='call' />
                  </ProtectedRoute>
                }
              />
              <Route
                path='sms'
                element={
                  <ProtectedRoute>
                    <RestorePasswordByAction restoreType='sms' />
                  </ProtectedRoute>
                }
              /> */}
            </Route>
            <Route
              path="by-email"
              element={
                <ProtectedRoute>
                  <RestoreByEmail />
                </ProtectedRoute>
              }
            />
          </Route>
        </Route>
        <Route
          path="sign-up"
          element={
            <ProtectedRoute>
              <SignUp />
            </ProtectedRoute>
          }
        />
        {routesProfile.map(({ path, component: Component }) => (
          <Route
            key={path}
            path={path}
            element={<ProtectedRoute isLogin><Component /></ProtectedRoute>}
          />
        ))}
        <Route path="/info">
          <Route path="oplata" element={<PaymentPage />} />
          <Route path="kontakty" element={<ContactsPage />} />
          <Route path="grafik-raboty" element={<WorkSchedulePage />} />
          <Route
            path="podarocnye-sertifikaty"
            element={<PresentCertificatePage />}
          />
          <Route path="vozvrat-i-obmen" element={<ReturnAndExchange />} />
          <Route path="sistema-skidok" element={<SaleSystemPage />} />
          <Route path="dlya-yuridiceskix-lic" element={<ForCompaniesPage />} />
          <Route path="o-nas" element={<AboutUsPage />} />
          <Route path="kak-zakazat" element={<HowBuyPage />} />
          <Route path="publichnaya-oferta" element={<PublicOfferingPage />} />
          <Route path="dostavka" element={<DeliveryPage />} />
        </Route>
        <Route path="cart">
          <Route index element={<UserCart />} />
          <Route path="checkout" element={<Checkout />} />
        </Route>
        <Route path="payment-status" element={<PaymentStatusPage />} />
        <Route path="/login/vendor" element={<NoIndexPage />} />
        {landingPagesConfig.map((page) => (
          <Route
            key={page.pageName}
            path={`page/${page.pageName}`}
            element={
              <LandingPage content={page.content()} pageName={page.pageName} />
            }
          />
        ))}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      {/* <Footer /> */}
      <FooterV2 />
    </>
  );
};

export default App;
