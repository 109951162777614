interface HeartIconProps {
  color: "red" | "gray" | "white";
  width?: number;
  height?: number;
  strokeColor?: string;
}

const HeartIcon: React.FC<HeartIconProps> = ({
  color,
  width = 20,
  height = 18,
  strokeColor = "#191B38"
}) => {
  const paths = {
    red: (
      <path
        d="M19.1512 1.92549C16.5208 -0.260733 13.1695 0.228388 10.993 2.4005C8.81508 0.229243 5.42434 -0.286872 2.8299 1.92987C0.123975 4.24187 -0.263528 8.12257 1.8694 10.8672C2.71456 11.9548 4.38926 13.6161 6.02993 15.1647C7.68018 16.7224 9.32769 18.1952 10.1406 18.9151L10.1515 18.9248C10.2328 18.9968 10.314 19.0687 10.3888 19.125C10.4728 19.1883 10.5759 19.2535 10.7073 19.2927C10.8929 19.348 11.0934 19.348 11.279 19.2927C11.4104 19.2535 11.5135 19.1883 11.5975 19.125C11.6723 19.0687 11.7535 18.9968 11.8348 18.9248L11.8457 18.9151C12.6586 18.1952 14.3061 16.7224 15.9564 15.1647C17.597 13.6161 19.2717 11.9548 20.1169 10.8672C22.2454 8.12825 21.9121 4.22017 19.1512 1.92549Z"
        fill="#FF0044"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    ),
    gray: (
      <path
        d="M19.1512 1.92549C16.5208 -0.260733 13.1695 0.228388 10.993 2.4005C8.81508 0.229243 5.42434 -0.286872 2.8299 1.92987C0.123975 4.24187 -0.263528 8.12257 1.8694 10.8672C2.71456 11.9548 4.38926 13.6161 6.02993 15.1647C7.68018 16.7224 9.32769 18.1952 10.1406 18.9151L10.1515 18.9248C10.2328 18.9968 10.314 19.0687 10.3888 19.125C10.4728 19.1883 10.5759 19.2535 10.7073 19.2927C10.8929 19.348 11.0934 19.348 11.279 19.2927C11.4104 19.2535 11.5135 19.1883 11.5975 19.125C11.6723 19.0687 11.7535 18.9968 11.8348 18.9248L11.8457 18.9151C12.6586 18.1952 14.3061 16.7224 15.9564 15.1647C17.597 13.6161 19.2717 11.9548 20.1169 10.8672C22.2454 8.12825 21.9121 4.22017 19.1512 1.92549Z"
        fill="#D0D5DD"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    ),
    white: (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9932 3.13581C8.9938 0.7984 5.65975 0.169643 3.15469 2.31001C0.649644 4.45038 0.296968 8.02898 2.2642 10.5604C3.89982 12.6651 8.84977 17.1041 10.4721 18.5408C10.6536 18.7016 10.7444 18.7819 10.8502 18.8135C10.9426 18.8411 11.0437 18.8411 11.1361 18.8135C11.2419 18.7819 11.3327 18.7016 11.5142 18.5408C13.1365 17.1041 18.0865 12.6651 19.7221 10.5604C21.6893 8.02898 21.3797 4.42787 18.8316 2.31001C16.2835 0.192157 12.9925 0.7984 10.9932 3.13581Z"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    )
  };

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {paths[color]}
    </svg>
  );
};

export default HeartIcon;
