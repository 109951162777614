import { createAsyncThunk } from "@reduxjs/toolkit";
import { userApi } from "../../../api/userApi";
import { productsApi } from "../../../api/productsApi";
import { ProductOffersPayload } from "../user";
import { CustomError, getErrorData, IErrorData, IGenericResponseRoot } from "utils/getErrorData";
import { IRequestSignUpUserThunk, IResponseSignUpUserThunk } from "types/ISignUpUserThunk";
import { IRequestSignInThunk, IResponseSignInThunk } from "types/ISignInUserThunk";
import { IRequestUpdateUserThunk } from "types/IUpdateUserThunk";
import { IRequestAddDeliveryAddressThunk } from "types/IAddDeliveryAddressThunk";
import { IRequestRemoveDeliveryAddressThunk } from "types/IRemoveDeliveryAddressThunk";
import { IRequestRemoveUserFavoriteProductThunk } from "types/IRemoveUserFavoriteProductThunk";
import { IRestoreUserPasswordRequest } from "types/IRestoreUserPassword";
import { IProductItem, IUser } from "types/IUser";
import { IDeliveryAdress } from "types/IDeliveryAdressAction";
import { IGetUserOrderDataRequest, IGetUserOrderDataResponse } from "types/IGetUserOrderData";
import { IFavoriteProducts } from "types/IFavoriteProductsActions";


export const isError = (data: object): data is IErrorData => {
  return "message" in data;
};

export const signUpUser = createAsyncThunk<
  IResponseSignUpUserThunk,
  IRequestSignUpUserThunk,
  { rejectValue: IErrorData; }
>(
  "user/signUpUser",
  async (data: IRequestSignUpUserThunk, { rejectWithValue } ) => {
    try {
      const registeredUserData = await userApi.signUp(data);

      if (isError(registeredUserData)) {
        throw new CustomError(registeredUserData.message, registeredUserData?.code);
      }

      if (!registeredUserData.response) {
        throw new CustomError("Не удалось получить данные о пользователе");
      }

      return registeredUserData.response;
    } catch(err) {
      const errorData = getErrorData(err);
      // eslint-disable-next-line no-console
      console.error("Ошибка при регистрации пользователя:", errorData.message);
      return rejectWithValue(errorData);
    }
  }
);

export const signInUser = createAsyncThunk<
  IResponseSignInThunk,
  IRequestSignInThunk,
  { rejectValue: IErrorData }
>(
  "user/signInUser",
  async (data: IRequestSignInThunk, { rejectWithValue }) => {
    try {
      const userData = await userApi.signIn(data);


      if (isError(userData)) {
        return rejectWithValue(userData);
      }

      if (!userData.response) {
        throw new CustomError("Не удалось получить данные о пользователе");
      }

      return userData.response;
    } catch (err) {
      const errorData = getErrorData(err);
      // eslint-disable-next-line no-console
      console.error("Ошибка при входе пользователя:", errorData.message);
      return rejectWithValue(errorData);
    }
  }
);

export const updateUserData = createAsyncThunk<
  IUser,
  IRequestUpdateUserThunk,
  { rejectValue: IErrorData }
>(
  "user/updateUserData",
  async (data: IRequestUpdateUserThunk, { rejectWithValue }) => {
    try {
      const userData = await userApi.updateUser(data);

      if (isError(userData)) {
        return rejectWithValue(userData);
      }

      if (!userData.response) {
        return rejectWithValue({message: "Не удалось получить данных о пользователе"});
      }

      return userData.response;
    } catch (err) {
      const errorData = getErrorData(err);
      // eslint-disable-next-line no-console
      console.error("Ошибка при обновлении данных пользователя:", errorData.message);
      return rejectWithValue(errorData);
    }
  }
);

export const addDeliveryAdress = createAsyncThunk<
  IDeliveryAdress[],
  IRequestAddDeliveryAddressThunk,
  { rejectValue: IErrorData }
>(
  "user/addDeliveryAdress",
  async (data: IRequestAddDeliveryAddressThunk, { rejectWithValue }) => {
    try {
      const userData = await userApi.addDeliveryAdress(data);

      if (isError(userData)) {
        return rejectWithValue(userData);
      }

      if (!userData.response) {
        throw new CustomError("Не удалось получить адреса доставки пользователя");
      }

      return userData.response;

    } catch (err) {
      const errorData = getErrorData(err);
      // eslint-disable-next-line no-console
      console.error("Ошибка при добавлении адреса доставки:", errorData.message);
      return rejectWithValue(errorData);
    }
  }
);

export const removeDeliveryAdress = createAsyncThunk<
IGenericResponseRoot<IDeliveryAdress[]>,
  IRequestRemoveDeliveryAddressThunk,
  { rejectValue: IErrorData }
>(
  "user/removeDeliveryAdress",
  async (data: IRequestRemoveDeliveryAddressThunk, { rejectWithValue }) => {
    try {
      const response = await userApi.removeDeliveryAdress(data.addressId);

      if (isError(response)) {
        return rejectWithValue(response);
      }

      return response;
    } catch (err) {
      const errorData = getErrorData(err);
      // eslint-disable-next-line no-console
      console.error("Ошибка при удалении адреса доставки:", errorData.message);
      return rejectWithValue(errorData);
    }
  }
);

export const getUserFavoriteProducts = createAsyncThunk<
  IFavoriteProducts,
  { limit: number; offset: number },
  { rejectValue: IErrorData }
>(
  "user/getUserFavoriteProducts",
  async ({ limit, offset }, { rejectWithValue }) => {
    try {
      const data = await userApi.getFavoriteProducts(limit, offset);

      if (isError(data)) {
        return rejectWithValue(data);
      }

      if (!data.response) {
        throw new CustomError("Не удалось получить данные об избранных товарах пользователя");
      }

      const response =  data.response;
      return response;

    } catch (err) {
      const errorData = getErrorData(err);
      // eslint-disable-next-line no-console
      console.error("Ошибка при получении избранных продуктов пользователя:", errorData.message);
      return rejectWithValue(errorData);
    }
  }
);

export const addUserFavoriteProduct = createAsyncThunk<
  IProductItem[],
  number,
  { rejectValue: IErrorData }
>(
  "user/addUserFavoriteProduct",
  async (data: number, { rejectWithValue }) => {
    try {
      const response = await userApi.addFavoriteProduct(data);


      if (isError(response)) {
        return rejectWithValue(response);
      }

      if (!response.response) {
        throw new CustomError("Не удалось получить данные об избранных товарах пользователя");
      }

      const favouriteProducts = response.response.items;

      return favouriteProducts;
    } catch (err) {
      const errorData = getErrorData(err);
      // eslint-disable-next-line no-console
      console.error("Ошибка при добавлении избранного продукта:", errorData.message);
      return rejectWithValue(errorData);
    }
  }
);

export const removeUserFavoriteProduct = createAsyncThunk<
  IProductItem[],
  IRequestRemoveUserFavoriteProductThunk,
  { rejectValue: IErrorData }
>(
  "user/removeUserFavoriteProduct",
  async (data: IRequestRemoveUserFavoriteProductThunk, { rejectWithValue }) => {
    try {
      const response = await userApi.removeFavoriteProduct(data.product);

      if (isError(response)) {
        return rejectWithValue(response);
      }

      if (!response.response) {
        throw new CustomError("Не удалось получить данные об избранных товарах пользователя");
      }

      const favoriteProducts = Object.values(response.response.items);

      return favoriteProducts;
    } catch (err) {
      const errorData = getErrorData(err);
      // eslint-disable-next-line no-console
      console.error("Ошибка при удалении избранного продукта:", errorData.message);
      return rejectWithValue(errorData);
    }
  }
);

export const getProductOffersByIds = createAsyncThunk<
  ProductOffersPayload,
  number,
  { rejectValue: IErrorData }
>(
  "user/getProductOffersByIds",
  async (id: number, { rejectWithValue }) => {
    try {
      const data = await productsApi.getProductById(id);

      if (isError(data)) {
        const errorData =  getErrorData(data);
        throw new CustomError(errorData.message, errorData?.code);
      }

      if (!data.response) {
        throw new CustomError("Не удалось получить данные о товарах");
      }

      return {
        id,
        offers: data.response.offers,
      };
    } catch (err) {
      const errorData = getErrorData(err);
      // eslint-disable-next-line no-console
      console.error("Ошибка при получении предложений по продукту:", errorData.message);
      return rejectWithValue(errorData);
    }
  }
);

export const restoreUserPassword = createAsyncThunk<
  IGenericResponseRoot<string> | IErrorData,
  IRestoreUserPasswordRequest,
  { rejectValue: IErrorData }
>(
  "user/restoreUserPassword",
  async (options: IRestoreUserPasswordRequest, { rejectWithValue }) => {
    try {
      const restoreResponse = await userApi.restoreUserPassword(options);

      if (isError(restoreResponse)) {
        return rejectWithValue(restoreResponse);
      }

      return restoreResponse;
    } catch (err) {
      const errorData = getErrorData(err);
      // eslint-disable-next-line no-console
      console.error("Ошибка при восстановлении пароля пользователя:", errorData.message);
      return rejectWithValue(errorData);
    }
  }
);

export const getUserOrdersThunk = createAsyncThunk<
  IGetUserOrderDataResponse,
  IGetUserOrderDataRequest,
  { rejectValue: IErrorData }
>(
  "user/getUserOrders",
  async (options, { rejectWithValue }) => {
    try {
      const data = await userApi.getUserOrders(options);

      if (isError(data)) {
        return rejectWithValue(data);
      }

      const response = data.response;
      if (!response) {
        throw new CustomError("Не удалось получить данные о заказах пользователя");
      }
      return response;

    } catch (err) {
      const errorData = getErrorData(err);
      // eslint-disable-next-line no-console
      console.error("Ошибка при получении заказов пользователя:", errorData.message);
      return rejectWithValue(errorData);
    }
  }
);