import cn from "classnames";
import styles from "./styles.module.scss";
import CheckIcon from "assets/profile/icons/CheckIcon";
import ChosenIcon from "assets/profile/icons/ChosenIcon";
import { InputHTMLAttributes } from "react";

interface CheckboxCustomProps extends InputHTMLAttributes<HTMLInputElement> {
  checked: boolean;
  onCustomChange: (checked: boolean) => void;
  label?: string;
  className?: string;
  labelClassName?: string;
  variant?: "default" | "check" | "chosen";
}

const CheckboxCustom: React.FC<CheckboxCustomProps> = ({
  checked,
  onCustomChange,
  label,
  disabled,
  className,
  labelClassName,
  variant = "default",
  ...props
}) => {
  const getIndicator = () => {
    switch (variant) {
    case "check":
      return <CheckIcon />;
    case "chosen":
      return <ChosenIcon />;
    default:
      return null;
    }
  };

  return (
    <label className={cn(styles.checkbox, className )}>
      <input
        type="checkbox"
        checked={checked}
        onChange={(e) => onCustomChange(e.target.checked)}
        disabled={disabled}
        style={{ display: "none" }}
        {...props}
      />
      <span
        className={cn(styles.checkbox__indicator, {
          [styles.default]: variant === "default",
          [styles.check]: variant === "check",
          [styles.chosen]: variant === "chosen",
          [styles.checked]: checked,
        })}
      >
        {(checked || disabled) && getIndicator()}
      </span>
      <span className={cn(styles.checkbox__label, labelClassName)}>{label}</span>
    </label>
  );
};

export default CheckboxCustom;