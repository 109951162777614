import { useAppDispatch, useAppSelector } from "store/reduxHooks";
import { useEffect, useRef, useState } from "react";
import useOutsideClick from "utils/hooks/useOutsideClick";
import { setUserCurrentCity } from "store/user/user";
import { Link, useLocation } from "react-router-dom";
import MenuIcon from "assets/profile/icons/MenuIcon";
import MenuCrossIcon from "assets/profile/icons/MenuCrossIcon";
import CitySelect from "components/CitySelect/CitySelect";
import CatalogTitlesV2 from "layouts/AdditionalHeader/CatalogTitlesV2/CatalogTitlesV2";
import UserBlockV2 from "layouts/AdditionalHeader/UserBlockV2/UserBlockV2";

import parfumartLogo from "../../assets/parfumart-logo.svg";

import cn from "classnames";
import styles from "./styles.module.scss";

const StickyHeaderV2: React.FC = () => {
  const { currentCity, deviceType, isOverlayActive } = useAppSelector((state) => state.user);
  const [isOpenCityModal, setIsOpenCityModal] = useState(false);
  const [showSelectCities, setShowSelectCities] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isCatalogOpen, setIsCatalogOpen] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [scrollDirection, setScrollDirection] = useState("up");
  const dispatch = useAppDispatch();
  const location = useLocation();

  const cityModalRef = useRef<HTMLDivElement | null>(null);
  const selectCitiesModalRef = useRef<HTMLDivElement | null>(null);
  const menuRef = useRef<HTMLDivElement | null>(null);

  useOutsideClick({
    ref: cityModalRef,
    cb: () => setIsOpenCityModal(false),
    disableClickOutside: showSelectCities
  });

  useOutsideClick({
    ref: selectCitiesModalRef,
    cb: () => setShowSelectCities(false)
  });

  const onCloseModals = () => {
    setIsOpenCityModal(false);
    setShowSelectCities(false);
  };

  const setCity = async (city: string): Promise<void> => {
    dispatch(setUserCurrentCity(city));
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (deviceType.isDesktop || deviceType.isLargeDesktop) {
        setIsScrolled(currentScrollY > 50);
        setScrollDirection(currentScrollY > lastScrollY ? "down" : "up");
        setLastScrollY(currentScrollY);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [deviceType, lastScrollY]);

  const toggleCatalog = () => {
    setIsCatalogOpen(!isCatalogOpen);
  };

  useEffect(() => {
    setIsCatalogOpen(false);
  }, [location]);

  return (
    <div className={styles.root}>
      {isCatalogOpen && (
        <div className={styles.overlay} onClick={toggleCatalog}></div>
      )}
      <div
        className={cn(styles.header, {
          [styles["header--overlay-active"]]: isOverlayActive,
        })}
      >
        <div
          className={cn(styles.wrapper, {
            [styles["wrapper--scrolled"]]:
              isScrolled && scrollDirection === "down",
            [styles["wrapper--open"]]: isCatalogOpen,
            [styles["wrapper--overlay-active"]]: isOverlayActive,
          })}
        >
          <div className={styles["wrapper-top"]}>
            {deviceType.isTablet || deviceType.isMobile ? (
              <div
                className={styles["menu-icon-wrapper"]}
                onClick={toggleCatalog}
              >
                {isCatalogOpen ? (
                  <MenuCrossIcon width={20}/>
                ) : (
                  <MenuIcon direction="left" />
                )}
              </div>
            ) : (
              <CitySelect
                currentCity={currentCity}
                setCurrentCity={setCity}
                cityModalRef={cityModalRef}
                selectCitiesModalRef={selectCitiesModalRef}
                setIsOpenCityModal={setIsOpenCityModal}
                setShowSelectCities={setShowSelectCities}
                isOpenCityModal={isOpenCityModal}
                showSelectCities={showSelectCities}
                onCloseModals={onCloseModals}
              />
            )}
            <Link to="/">
              <img
                src={parfumartLogo}
                alt="parfumart_logo"
                className={styles.logo}
              />
            </Link>
            <UserBlockV2 />
          </div>
        </div>
      </div>
      <div
        className={cn(styles["catalog-titles"], {
          [styles["catalog-titles--hidden"]]:
            isScrolled &&
            scrollDirection === "down" &&
            (deviceType.isDesktop || deviceType.isLargeDesktop),
          [styles["catalog-titles--open"]]:
            (scrollDirection === "up" &&
              (deviceType.isDesktop || deviceType.isLargeDesktop)) ||
            (isCatalogOpen && (deviceType.isTablet || deviceType.isMobile))
        })}
        ref={menuRef}
      >
        <CatalogTitlesV2
          onCloseMenu={() => setIsCatalogOpen(false)}
          currentCity={currentCity}
          setCurrentCity={setCity}
          cityModalRef={cityModalRef}
          selectCitiesModalRef={selectCitiesModalRef}
          setIsOpenCityModal={setIsOpenCityModal}
          setShowSelectCities={setShowSelectCities}
          isOpenCityModal={isOpenCityModal}
          showSelectCities={showSelectCities}
          onCloseModals={onCloseModals}
          menuRef={menuRef}
        />
      </div>
    </div>
  );
};

export default StickyHeaderV2;
