import { toast } from "react-toastify";
import { getUserToken, removeTokens, setValueInStorage } from "../utils/localStorage";
import {
  CustomError,
  getErrorData,
  IErrorData,
  IGenericResponseRoot
} from "utils/getErrorData";
import { IRequestSignIn, IResponseSignIn } from "types/ISignIn";
import { IResponseRefreshUser } from "types/IRefreshUser";
import { IRequestSignUp, IResponseSignUp } from "types/ISingUp";
import { IRequestUpdateUser } from "types/IUpdateUser";
import {
  IDeliveryAdress,
  IDeliveryAdressAddRequest
} from "types/IDeliveryAdressAction";
import { IChangeUserPasswordRequest } from "types/IChangeUserPassword";
import {
  IFavoriteProducts,
  IFavoriteProductsForDelete
} from "types/IFavoriteProductsActions";
import {
  IGetUserOrderDataRequest,
  IGetUserOrderDataResponse
} from "types/IGetUserOrderData";
import { IGetUserCartRequest, IGetUserCartResponse } from "types/IGetUserCart";
import {
  IRequestAddToCartAuthorized,
  IResponseAddToCartAuthorize
} from "types/IAddProductToCart";
import { IResponseAddToCartPublic } from "types/IAddProductToCartPublic";
import {
  ICreateNewOrderNonAuthorizedUsersRequest,
  ICreateNewOrderNonAuthorizedUsersResponse
} from "types/ICreateNewOrderNonAuthorizedUsers";
import {
  ICreateNewOrderAuthorizedUsersRequest,
  ICreateNewOrderAuthorizedUsersResponse
} from "types/ICreateNewOrderAuthorizedUsers";
import { IRestoreUserPasswordRequest } from "types/IRestoreUserPassword";
import { objectEntries } from "utils/helpers/typeGuardObjEntries";
import {
  IGetOrderPaymentLinkRequest,
  IOrderPaymentLinkResponse
} from "types/IGetOrderPaymentLink";
import { YANDEX_METRICS_CLIENT_ID_KEY } from "utils/yandexMetrics/yandexMetricsCore";
import { IUser } from "types/IUser";
import { Offer } from "types/UserApiTypes";
import { fetchPrivate } from "utils/fetchPrivate";
import { IOrderStatus } from "types/IOrderStatus";

const checkUser = async (): Promise<
  IGenericResponseRoot<IUser> | IErrorData
> => {
  const token = getUserToken();
  try {
    const res = await fetchPrivate(
      `${process.env.REACT_APP_BACKEND_URL}/api/private/account`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    if (res.status === 500) {
      throw new CustomError(
        "При получении данных о пользователе произошла необработанная ошибка",
        500
      );
    }

    const data: IGenericResponseRoot<IUser> = await res.json();

    if (!res.ok || data.response === null) {
      const errorData = getErrorData(data);
      throw new CustomError(errorData.message, errorData?.code);
    }

    return data;
  } catch (error) {
    const errorData = getErrorData(error);
    return errorData;
  }
};

const signIn = async (
  user: IRequestSignIn
): Promise<IGenericResponseRoot<IResponseSignIn> | IErrorData> => {
  const clientID = localStorage.getItem(YANDEX_METRICS_CLIENT_ID_KEY);
  const formData = new FormData();

  formData.append("username", user.username);
  formData.append("password", user.password);

  try {
    const fetchParams = {
      method: "POST",
      body: formData,
      headers: {}
    };

    const allowClientIdInHeaders =
      process.env.REACT_APP_CLIENT_ID_ALLOW === "true";

    if (allowClientIdInHeaders && clientID && !!clientID.length) {
      fetchParams.headers = {
        ...fetchParams.headers,
        [YANDEX_METRICS_CLIENT_ID_KEY]: clientID
      };
    }

    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/public/login`,
      fetchParams
    );

    if (res.status === 500) {
      throw new Error("Произошла необработанная ошибка при авторизации.");
      // TODO: Обработать отправку ошибок на бэк в теории
    }

    const data: IGenericResponseRoot<IResponseSignIn> = await res.json();

    if (!res.ok) {
      const errorData = getErrorData(data);
      throw new CustomError(errorData.message, errorData?.code);
    }

    // даже в 200-й может быть ошибка в meta - сделать проверку 01-11-2024
    if (data?.meta?.error) {
      const errorData = getErrorData(data);
      throw new CustomError(errorData.message, errorData?.code);
    }

    return data;
  } catch (error) {
    const errorData = getErrorData(error);
    return errorData;
  }
};

const refreshUser = async (): Promise<
  IGenericResponseRoot<IResponseRefreshUser> | IErrorData
> => {
  const formData = new FormData();

  const accessToken: string | null = localStorage.getItem("accessToken");
  const refreshToken: string | null = localStorage.getItem("refreshToken");
  const userKey: string | null = localStorage.getItem("userKey");

  if (accessToken) {
    formData.append("token", accessToken);
  }
  if (refreshToken) {
    formData.append("refresh_token", refreshToken);
  }
  if (userKey) {
    formData.append("refresh_key", userKey);
  }

  try {
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/public/login/refresh`,
      {
        method: "POST",
        body: formData
      }
    );

    if (res.status === 500) {
      throw new CustomError(
        "При повторной авторизации произошла необработанная ошибка",
        500
      );
    }

    const data: IGenericResponseRoot<IResponseRefreshUser> = await res.clone().json();

    if (!res.ok) {
      const errorData = getErrorData(data);
      throw new CustomError(errorData.message, errorData?.code);
    }

    return data;
  } catch (error) {
    // обнуляем поля токена ключа и рефреш токена при ошибке
    removeTokens();
    setValueInStorage({ key: "isLoggedOut", value: "true" });
    toast.info("Авторизация истекла, авторизуйтесь повторно");
    setTimeout(() => {
      window.location.reload();
    }, 2000);
    const errorData = getErrorData(error);
    return errorData;
  }
};

const signUp = async (
  user: IRequestSignUp
): Promise<IGenericResponseRoot<IResponseSignUp> | IErrorData> => {
  const clientID = localStorage.getItem(YANDEX_METRICS_CLIENT_ID_KEY);
  const formData = new FormData();

  formData.append("phone", user.phone);
  formData.append("name", user.name);
  formData.append("password", user.password);

  if (user?.email) {
    formData.append("email", user.email);
  }

  try {
    const fetchParams = {
      method: "POST",
      body: formData,
      headers: {}
    };

    const allowClientIdInHeaders =
      process.env.REACT_APP_CLIENT_ID_ALLOW === "true";

    if (allowClientIdInHeaders && clientID && !!clientID.length) {
      fetchParams.headers = {
        ...fetchParams.headers,
        [YANDEX_METRICS_CLIENT_ID_KEY]: clientID
      };
    }

    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/public/account/register`,
      fetchParams
    );

    if (res.status === 500) {
      throw new Error("Произошла необработанная ошибка при регистрации.");
      // TODO: Обработать отправку ошибок на бэк в теории
    }

    const data: IGenericResponseRoot<IResponseSignUp> = await res.json();

    if (!res.ok) {
      const errorData = getErrorData(data);
      throw new CustomError(errorData.message, errorData?.code);
    }

    // даже в 200-й может быть ошибка в meta - сделать проверку 01-11-2024
    if (data?.meta?.error) {
      const errorData = getErrorData(data);
      throw new CustomError(errorData.message, errorData?.code);
    }

    return data;
  } catch (error) {
    const errorData = getErrorData(error);
    return errorData;
  }
};

const updateUser = async (
  user: IRequestUpdateUser
): Promise<IGenericResponseRoot<IUser> | IErrorData> => {
  const data: Partial<IRequestUpdateUser> = {};
  const token = getUserToken();

  // eslint-disable-next-line prefer-const
  for (let key in user) {
    if (user[key as keyof IRequestUpdateUser]) {
      data[key as keyof IRequestUpdateUser] =
        user[key as keyof IRequestUpdateUser];
    }
  }
  try {
    const res = await fetchPrivate(
      `${process.env.REACT_APP_BACKEND_URL}/api/private/account/update`,
      {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    if (res.status === 500) {
      throw new CustomError(
        "Произошла необработанная ошибка при обновлении данных пользователя.",
        500
      );
      // TODO: Обработать отправку ошибок на бэк в теории
    }

    const response: IGenericResponseRoot<IUser> = await res.json();

    if (!res.ok) {
      const errorData = getErrorData(response);
      throw new CustomError(errorData.message, errorData?.code);
    }
    return response;
  } catch (error) {
    const errorData = getErrorData(error);
    return errorData;
  }
};

const addDeliveryAdress = async (
  user: IDeliveryAdressAddRequest
): Promise<IGenericResponseRoot<IDeliveryAdress[]> | IErrorData> => {
  const data: Partial<IDeliveryAdressAddRequest> = {};
  const token = getUserToken();

  for (const [key, value] of objectEntries(user)) {
    if (value !== undefined && value !== null) {
      data[key] = value;
    }
  }

  try {
    const res = await fetchPrivate(
      `${process.env.REACT_APP_BACKEND_URL}/api/private/account/delivery-address`,
      {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    const response: IGenericResponseRoot<IDeliveryAdress[]> = await res.json();

    if (!res.ok) {
      const errorData = getErrorData(response);
      throw new CustomError(errorData.message, errorData?.code);
    }
    return response;
  } catch (error) {
    const errorData = getErrorData(error);
    return errorData;
  }
};

const removeDeliveryAdress = async (
  addressId: number | string
): Promise<IGenericResponseRoot<IDeliveryAdress[]> | IErrorData> => {
  const token = getUserToken();
  try {
    const res = await fetchPrivate(
      `${process.env.REACT_APP_BACKEND_URL}/api/private/account/delivery-address/${addressId}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    if (res.status === 500) {
      throw new CustomError(
        "При удалении адреса доставки произошла необработанная ошибка",
        500
      );
    }

    const response: IGenericResponseRoot<IDeliveryAdress[]> = await res.json();
    if (!res.ok) {
      const errorData = getErrorData(response);
      throw new CustomError(errorData.message, errorData?.code);
    }
    return response;
  } catch (error) {
    const errorData = getErrorData(error);
    return errorData;
  }
};

const changeUserPassword = async (
  data: IChangeUserPasswordRequest
): Promise<IGenericResponseRoot<string> | IErrorData> => {
  const token = getUserToken();
  try {
    const res = await fetchPrivate(
      `${process.env.REACT_APP_BACKEND_URL}/api/private/account/change-password`,
      {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    if (res.status === 500) {
      throw new CustomError(
        "Произошла необработанная ошибка при смене пароля.",
        500
      );
      // TODO: Обработать отправку ошибок на бэк в теории
    }

    const responseData: IGenericResponseRoot<string> = await res.json();

    if (!res.ok) {
      const errorData = getErrorData(responseData);
      throw new CustomError(errorData.message, errorData?.code);
    }

    return responseData;
  } catch (error) {
    const errorData = getErrorData(error);
    return errorData;
  }
};

const getFavoriteProducts = async (
  limit: number,
  offset: number
): Promise<
  IGenericResponseRoot<IFavoriteProducts> | IErrorData
> => {
  const token = getUserToken();
  const queryParams = new URLSearchParams({ limit: limit.toString(), offset: offset.toString() });
  try {
    const res = await fetchPrivate(
      `${process.env.REACT_APP_BACKEND_URL}/api/private/favorite-products?${queryParams}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    if (res.status === 500) {
      throw new CustomError(
        "При получении избранных товаров произошла необработанная ошибка",
        500
      );
    }

    const response: IGenericResponseRoot<IFavoriteProducts> = await res.json();
    if (!res.ok) {
      const errorData = getErrorData(response);
      throw new CustomError(errorData.message, errorData?.code);
    }
    return response;
  } catch (error) {
    const errorData = getErrorData(error);
    return errorData;
  }
};

const addFavoriteProduct = async (
  id: number
): Promise<IGenericResponseRoot<IFavoriteProducts> | IErrorData> => {
  const token = getUserToken();
  try {
    const res = await fetchPrivate(
      `${process.env.REACT_APP_BACKEND_URL}/api/private/favorite-product`,
      {
        method: "POST",
        body: JSON.stringify({ product: id }),
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    if (res.status === 500) {
      throw new CustomError(
        "При добавлении товара в избранное произошла необработанная ошибка",
        500
      );
    }

    const response: IGenericResponseRoot<IFavoriteProducts> = await res.json();
    if (!res.ok) {
      const errorData = getErrorData(response);
      throw new CustomError(errorData.message, errorData?.code);
    }
    return response;
  } catch (error) {
    const errorData = getErrorData(error);
    return errorData;
  }
};

const removeFavoriteProduct = async (
  id: number
): Promise<IGenericResponseRoot<IFavoriteProductsForDelete> | IErrorData> => {
  const token = getUserToken();
  try {
    const res = await fetchPrivate(
      `${process.env.REACT_APP_BACKEND_URL}/api/private/favorite-product`,
      {
        method: "DELETE",
        body: JSON.stringify({ product: id }),
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    if (res.status === 500) {
      throw new CustomError(
        "При удалении избранного товара произошла необработанная ошибка",
        500
      );
    }

    const response: IGenericResponseRoot<IFavoriteProductsForDelete> =
      await res.json();
    if (!res.ok) {
      const errorData = getErrorData(response);
      throw new CustomError(errorData.message, errorData?.code);
    }

    return response;
  } catch (error) {
    const errorData = getErrorData(error);
    return errorData;
  }
};

const getUserOrders = async (
  options: IGetUserOrderDataRequest
): Promise<IGenericResponseRoot<IGetUserOrderDataResponse> | IErrorData> => {
  try {
    const requestData = objectEntries(options).map(([key, value], idx) => {
      if (typeof value === "undefined") {
        return "";
      }
      if (idx === 0) {
        return `?${key}=${value}`;
      }
      return `&${key}=${value}`;
    });

    const token = getUserToken();

    const res = await fetchPrivate(
      `${process.env.REACT_APP_BACKEND_URL}/api/private/orders${requestData.join("")}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    if (res.status === 500) {
      throw new CustomError(
        "Произошла необработанная ошибка при получении заказов",
        500
      );
    }

    const response: IGenericResponseRoot<IGetUserOrderDataResponse> =
      await res.json();

    if (!res.ok) {
      const errorData = getErrorData(response);
      throw new CustomError(errorData.message, errorData?.code);
    }

    return response;
  } catch (error) {
    return getErrorData(error);
  }
};

const getUserCart = async (
  options: IGetUserCartRequest
): Promise<IGenericResponseRoot<IGetUserCartResponse> | IErrorData> => {
  try {
    const requestData = objectEntries(options).map(([key, value], idx) => {
      if (typeof value === "undefined" || !value) {
        return "";
      }
      if (idx === 0) {
        return `?${key}=${value}`;
      }
      return `&${key}=${value}`;
    });

    const token = getUserToken();

    const res = await fetchPrivate(
      `${process.env.REACT_APP_BACKEND_URL}/api/private/basket${requestData.join("")}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    if (res.status === 500) {
      throw new CustomError(
        "Произошла необработанная ошибка при получении корзины пользователя",
        500
      );
      // TODO: Обработать отправку ошибок на бэк в теории
    }

    const response: IGenericResponseRoot<IGetUserCartResponse> =
      await res.json();
    if (!res.ok) {
      const errorData = getErrorData(response);
      throw new CustomError(errorData.message, errorData?.code);
    }
    return response;
  } catch (error) {
    return getErrorData(error);
  }
};

const addProductToCart = async (
  requestData: IRequestAddToCartAuthorized
): Promise<IGenericResponseRoot<IResponseAddToCartAuthorize> | IErrorData> => {
  const token = getUserToken();
  try {
    const res = await fetchPrivate(
      `${process.env.REACT_APP_BACKEND_URL}/api/private/basket`,
      {
        method: "POST",
        body: JSON.stringify(requestData),
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    if (res.status === 500) {
      throw new CustomError(
        "Произошла ошибка добавления товара в корзину авторизованным пользователем",
        500
      );
    }

    const response: IGenericResponseRoot<IResponseAddToCartAuthorize> =
      await res.json();

    if (!res.ok) {
      const errorData = getErrorData(response);
      throw new CustomError(errorData.message, errorData?.code);
    }

    return response;
  } catch (error) {
    return getErrorData(error);
  }
};

const addProductToCartPublic = async (
  items: Offer[]
): Promise<IGenericResponseRoot<IResponseAddToCartPublic> | IErrorData> => {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/public/basket`,
      {
        method: "POST",
        body: JSON.stringify({ offers: items })
      }
    );

    if (res.status === 500) {
      throw new CustomError(
        "Произошла необработанная ошибка при добавлении товаров в публичную карзину"
      );
      // TODO: Обработать отправку ошибок на бэк в теории
    }

    const data = await res.json();

    if (!res.ok) {
      const errorData = getErrorData(data);
      return errorData;
    }
    return data as IGenericResponseRoot<IResponseAddToCartPublic>;
  } catch (error) {
    return getErrorData(error);
  }
};

const addNewOrderForAuthorizedUsers = async (
  options: ICreateNewOrderAuthorizedUsersRequest
): Promise<
  IGenericResponseRoot<ICreateNewOrderAuthorizedUsersResponse> | IErrorData
> => {
  try {
    const clientID = localStorage.getItem(YANDEX_METRICS_CLIENT_ID_KEY);
    const token = getUserToken();

    const fetchParams = {
      headers: {
        Authorization: `Bearer ${token}`
      } as HeadersInit,
      method: "POST",
      body: JSON.stringify(options)
    };

    const allowClientIdInHeaders =
      process.env.REACT_APP_CLIENT_ID_ALLOW === "true";

    if (allowClientIdInHeaders && clientID && !!clientID.length) {
      fetchParams.headers = {
        ...fetchParams.headers,
        [YANDEX_METRICS_CLIENT_ID_KEY]: clientID
      };
    }

    const res = await fetchPrivate(
      `${process.env.REACT_APP_BACKEND_URL}/api/private/order`,
      fetchParams
    );

    if (res.status === 500) {
      throw new CustomError("Произошла необработанная ошибка при оформлении заказа");
    }

    const response: IGenericResponseRoot<ICreateNewOrderAuthorizedUsersResponse> =
      await res.json();

    if (!res.ok) {
      const errorData = getErrorData(response);
      throw new CustomError(errorData.message, errorData?.code);
    }

    return response;
  } catch (error) {
    return getErrorData(error);
  }
};

const addNewOrderForNonAuthorizedUsers = async (
  options: ICreateNewOrderNonAuthorizedUsersRequest
): Promise<
  IGenericResponseRoot<ICreateNewOrderNonAuthorizedUsersResponse> | IErrorData
> => {
  try {
    const clientID = localStorage.getItem(YANDEX_METRICS_CLIENT_ID_KEY);
    const fetchParams = {
      headers: {
        "Content-Type": "application/json"
      } as HeadersInit,
      method: "POST",
      body: JSON.stringify(options)
    };

    const allowClientIdInHeaders =
      process.env.REACT_APP_CLIENT_ID_ALLOW === "true";

    if (allowClientIdInHeaders && clientID && !!clientID.length) {
      fetchParams.headers = {
        ...fetchParams.headers,
        [YANDEX_METRICS_CLIENT_ID_KEY]: clientID
      };
    }
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/public/order`,
      fetchParams
    );

    if (res.status === 500) {
      throw new CustomError("Произошла необработанная ошибка при оформлении заказа");
    }

    const response: IGenericResponseRoot<ICreateNewOrderNonAuthorizedUsersResponse> = await res.json();

    if (!res.ok) {
      const errorData = getErrorData(response);
      return errorData;
    }
    return response;
  } catch (error) {
    return getErrorData(error);
  }
};

const restoreUserPassword = async (
  options: IRestoreUserPasswordRequest
): Promise<IGenericResponseRoot<string> | IErrorData> => {
  try {
    if (options.method !== "phone" && options.method !== "email") {
      throw new Error("Метод восстановления пароля указан неверно");
    }

    const bodyData = {
      method: options.method,
      contact: options.contact,
      code: options.code,
      new_password: options.new_password
    };

    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/public/account/password/restore`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(bodyData)
      }
    );

    if (res.status === 500) {
      throw new Error(
        "Произошла необработанная ошибка при восстановлении пароля."
      );
      // TODO: Обработать отправку ошибок на бэк в теории
    }

    const response: IGenericResponseRoot<string> = await res.json();

    if (!res.ok) {
      const errorData = getErrorData(response);
      throw new CustomError(errorData.message, errorData?.code);
    }

    // даже в 200-й может быть ошибка в meta - сделать проверку 01-11-2024
    if (response?.meta?.error) {
      const errorData = getErrorData(response);
      throw new CustomError(errorData.message, errorData?.code);
    }

    return response;
  } catch (err) {
    return getErrorData(err);
  }
};

const getOrderPaymentLink = async (
  options: IGetOrderPaymentLinkRequest
): Promise<IGenericResponseRoot<IOrderPaymentLinkResponse> | IErrorData> => {
  try {
    const token = getUserToken();

    const url = token
      ? `${process.env.REACT_APP_BACKEND_URL}/api/private/payment`
      : `${process.env.REACT_APP_BACKEND_URL}/api/public/payment`;

    const headers: Record<string, string> = {
      "Content-Type": "application/json"
    };

    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }

    const res = await fetchPrivate(url, {
      method: "POST",
      body: JSON.stringify(options),
      headers
    });

    if (res.status === 500) {
      throw new CustomError(
        "При получении ссылки на оплату произошла ошибка",
        500
      );
    }

    const data: IGenericResponseRoot<IOrderPaymentLinkResponse> =
      await res.json();

    if (!res.ok) {
      const errorData = getErrorData(data);
      throw new CustomError(errorData.message, errorData?.code);
    }

    return data;
  } catch (error) {
    const errorData = getErrorData(error);
    // eslint-disable-next-line no-console
    console.error(errorData.message);
    return errorData;
  }
};

const getOrderStatus = async (uuid: string): Promise<IGenericResponseRoot<IOrderStatus> | IErrorData> => {
  try {
    const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/public/order/status?order=${uuid}`, {
      method: "GET"
    });

    if (res.status === 500) {
      throw new CustomError(
        "При получении статуса заказа произошла ошибка",
        500
      );
    }

    const data: IGenericResponseRoot<IOrderStatus> = await res.json();

    if (!res.ok) {
      const errorData = getErrorData(data);
      throw new CustomError(errorData.message, errorData?.code);
    }

    return data;
  } catch (error) {
    const errorData = getErrorData(error);
    // eslint-disable-next-line no-console
    console.error(errorData.message);
    return errorData;
  }
};

export const userApi = {
  checkUser,
  signIn,
  refreshUser,
  signUp,
  updateUser,
  addDeliveryAdress,
  removeDeliveryAdress,
  changeUserPassword,
  getFavoriteProducts,
  addFavoriteProduct,
  removeFavoriteProduct,
  getUserOrders,
  getUserCart,
  addProductToCart,
  addProductToCartPublic,
  addNewOrderForAuthorizedUsers,
  addNewOrderForNonAuthorizedUsers,
  restoreUserPassword,
  getOrderPaymentLink,
  getOrderStatus
};
