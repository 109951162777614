import LoadingSpinnerCustom from "components/UI/LoadingSpinnerCustom/LoadingSpinnerCustom";
import styles from "./styles.module.scss";

interface CustomDropdownV2Props<T> {
  showDropdown: boolean;
  isLoading: boolean;
  noResultsText: string;
  items: T[];
  onItemSelect: (item: T) => void;
  processItem: (item: T) => React.ReactNode;
  handleMouseDown?: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  isNoResultSearch?: boolean;
}

const CustomDropdownV2 = <T,>({
  showDropdown,
  isLoading,
  noResultsText,
  items,
  onItemSelect,
  processItem,
  handleMouseDown,
  isNoResultSearch
}: CustomDropdownV2Props<T>) => {
  if (!showDropdown) return null;

  return (
    <div className={styles.dropdown}>
      {isLoading && (
        <div className={styles["dropdown-item--loading"]} data-testid={"loading"}>
          <LoadingSpinnerCustom color="dark" />
        </div>
      )}
      {!isLoading && isNoResultSearch && (
        <div className={styles["dropdown-item--result"]} data-testid={"no-result-text"}>{noResultsText}</div>
      )}
      {!isLoading &&
        items.length > 0 &&
        items.map((item, index) => (
          <div
            key={index}
            className={styles["dropdown-item"]}
            onMouseDown={handleMouseDown}
            onClick={() => onItemSelect(item)}
            data-testid={`dropdown-item-${index}`}
          >
            {processItem(item)}
          </div>
        ))}
    </div>
  );
};

export default CustomDropdownV2;
