import FooterInfo from "./components/FooterInfo/FooterInfo";
import ListWithLinksV2 from "./components/ListWithLinksV2/ListWithLinksV2";
import SocialLinkV2 from "./components/SocialLinkV2/SocialLinkV2";
import SubscriptionBlockV2 from "./components/SubscriptionBlockV2/SubscriptionBlockV2";

import styles from "./styles.module.scss";

const FooterV2: React.FC = () => {
  return (
    <footer className={styles.root}>

      <span itemScope itemType="https://schema.org/Organization">
        <meta
          itemProp="name"
          content="Parfumart.ru - Интернет-магазин парфюмерии и косметики."
        />
        <span
          itemProp="address"
          itemScope
          itemType="https://schema.org/PostalAddress"
        >
          <meta
            itemProp="streetAddress"
            content="Меланжевая, 10. Деловой центр AVM-ORSETTO, 1 этаж, офис 102."
          />
          <meta itemProp="addressLocality" content="Россия, Краснодар" />
          <meta itemProp="telephone" content="8 (861) 290-21-10" />
          <meta itemProp="telephone" content="8 (918) 977-21-10" />
          <meta itemProp="email" content="client@parfumart.ru" />
          <meta itemProp="email" content="ceo@parfumart.ru" />
        </span>
      </span>

      <section className={styles.top}>
        <SubscriptionBlockV2 />
        <SocialLinkV2 />
      </section>
      <ListWithLinksV2 />
      <div className={styles.divider}></div>
      <FooterInfo />
    </footer>
  );
};

export default FooterV2;
