import InstagramIcon from "assets/icons/social-icons/InstagramIcon";
import TelegramIcon from "assets/icons/social-icons/TelegramIcon";
import VKIcon from "assets/icons/social-icons/VKIcon";

import styles from "./styles.module.scss";

const SocialLinkV2: React.FC = () => {
  const socialLinks = [
    { id: 1, href: `${process.env.REACT_APP_SOCIAL_NET_LINK_VK}`, icon: <VKIcon /> },
    { id: 2, href: `${process.env.REACT_APP_SOCIAL_NET_LINK_INST}`, icon: <InstagramIcon /> },
    { id: 3, href: `${process.env.REACT_APP_SOCIAL_NET_LINK_TLG}`, icon: <TelegramIcon /> }
  ];

  return (
    <div className={styles.social}>
      <h3 className={styles.social__title}>Мы в соц. сетях</h3>
      <div className={styles.social__links}>
        {socialLinks.map((link) => (
          <a
            key={link.id}
            href={link.href}
            className={styles.social__link}
            target="_blank"
            rel="noreferrer"
          >
            {link.icon}
          </a>
        ))}
      </div>
    </div>
  );
};

export default SocialLinkV2;
