import React, { forwardRef, useImperativeHandle, useRef } from "react";
import ProfileRoutes from "../ProfileRoutes/ProfileRoutes";
import usePreviousRoute from "utils/hooks/usePreviousRouteInProfile";
import CustomButtonV2 from "components/UI/CustomButtonV2/CustomButtonV2";
import styles from "./styles.module.scss";
import cn from "classnames";

interface ProfileWrapperProps {
  title?: string;
  children: React.ReactNode;
  titleClassName?: string;
  "data-testid"?: string;
}

const ProfileWrapper = forwardRef<HTMLDivElement, ProfileWrapperProps>(({ title, children, titleClassName, "data-testid": dataTestId }, ref) => {
  const localRef = useRef<HTMLDivElement | null>(null);

  useImperativeHandle(ref, () => localRef.current ? localRef.current : {} as HTMLDivElement);

  return (
    <main className={styles.page} ref={localRef} data-testid={dataTestId}>
      <nav className={styles.navigation}>
        <ProfileRoutes />
      </nav>
      <section className={styles.content}>
        <nav className={styles.content__navigation}>
          <CustomButtonV2
            title="Назад"
            onClick={usePreviousRoute()}
            size="extra-small"
            color="secondary"
            iconLeft="Arrow"
            iconLeftProps={{ direction: "left" }}
          />
        </nav>
        {title && <h1 className={cn(styles.content__title, titleClassName)}>{title}</h1>}
        {children}
      </section>
    </main>
  );
});

ProfileWrapper.displayName = "ProfileWrapper";

export default ProfileWrapper;