import styles from "./styles.module.scss";

const FooterInfo: React.FC = () => {
  return (
    <section className={styles.info}>
      <div className={styles.info__column}>
        <p className={styles.info__text}>©2012-{new Date().getFullYear()} Parfumart.ru</p>
        <p className={styles.info__text}>Интернет-магазин парфюмерии и косметики.</p>
      </div>
      <div className={styles.info__column}>
        <p className={styles.info__text}>Только оригинальная, сертифицированная продукция.</p>
        <p className={styles.info__text}>
          Доставка по всей территории России. Все права защищены.
        </p>
      </div>
    </section>
  );
};

export default FooterInfo;