import WhatsAppIcon from "assets/icons/social-icons/WhatsAppIcon";
import TelegramIcon from "assets/icons/social-icons/TelegramIcon";
import CrossIcon from "assets/profile/icons/CrossIcon";

import cn from "classnames";
import styles from "./styles.module.scss";
import { forwardRef } from "react";

interface ContactsInnerContentProps {
  containerStyles?: string;
  onClose?: () => void;
}

const ContactsInnerContent = forwardRef<HTMLDivElement, ContactsInnerContentProps>(
  (props, ref) => {
    const socialLinks = [
      { id: 1, href: `${process.env.REACT_APP_SOCIAL_NET_LINK_WHATSAPP_HELP}`, icon: <WhatsAppIcon /> },
      { id: 2, href: `${process.env.REACT_APP_SOCIAL_NET_LINK_TLG}`, icon: <TelegramIcon /> }
    ];

    return (
      <div ref={ref} className={cn(styles.root, props.containerStyles)}>
        <button className={styles["close-cross"]} onClick={props.onClose}>
          <CrossIcon width={13} height={13} color="#98A2B3" />
        </button>
        <h5 className={styles["head-title"]}>Контакты</h5>
        <div className={styles.divider}></div>
        <p className={styles["main-title"]}>Позвонить нам</p>
        <p className={styles["phones-wrapper"]}>
          <span className={styles.subtitle}>8 (861) 290 21 10</span>
          <span className={styles.subtitle}>8 (918) 977 21 10</span>
        </p>
        <div className={styles.divider}></div>
        <p className={styles["main-title"]}>Написать нам</p>
        <p className={styles.subtitle}>client@parfumart.ru</p>
        <div className={styles.divider}></div>
        <p className={styles["main-title"]}>Связаться с консультантом</p>
        <div className={styles["links-wrapper"]}>
          {socialLinks.map((link) => (
            <a
              key={link.id}
              href={link.href}
              className={styles.link}
              target="_blank"
              rel="noreferrer"
            >
              {link.icon}
            </a>
          ))}
        </div>
      </div>
    );
  }
);

ContactsInnerContent.displayName = "ContactsInnerContent";

export default ContactsInnerContent;
